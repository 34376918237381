import { IDEVIEWTABS, IDEVIEWTABS_MOBILE, TAB_ICONS_NAMES } from '@/utils/tabs'
enum PopoverPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  START = 'start',
  END = 'end'
}
interface Popover {
  title: string
  description: string
  position:
    | PopoverPosition.TOP
    | PopoverPosition.BOTTOM
    | PopoverPosition.LEFT
    | PopoverPosition.RIGHT
    | PopoverPosition.CENTER
  alignment?: PopoverPosition.START | PopoverPosition.CENTER | PopoverPosition.END
}

export interface OnboardingStep {
  element: string
  popover: Popover
  idType?: IdeFlowType
  conditionType: string[]
  tabName?: string
  tabMobileId?: string
}

export enum IdeFlowType {
  BUTTON = 'button',
  TAB = 'tabs'
}

export enum BoardingElement {
  IDE_CODE_EDITOR = 'ideCodeEditor',
  EXECUTE_IDE = 'execute-ide',
  IDE_SETTING = 'ide-settings',
  INSTALL_PACKAGES = 'install-package',
  IDE_DATABASE_INFO = 'ideDatabaseInfo',
  IDE_LIB_TAB = 'lib-tab',
  IDE_DATABSE_OPEN = 'open-database',
  IDE_SHARE = 'instant-share-ide',
  IDE_HELP = 'ide-help'
}

export const currentReleaseDate = '2024-12-04'

export const IdeOnboardngFlow: OnboardingStep[] = [
  {
    element: `#${BoardingElement.IDE_CODE_EDITOR}`,
    popover: {
      title: 'Get started with the IDE',
      description:
        'Write your code in the IDE input window. Changes are auto-saved in the background.',
      position: PopoverPosition.CENTER
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_CODE_EDITOR]
  },
  {
    element: `#${BoardingElement.EXECUTE_IDE}`,
    popover: {
      title: 'Execute code',
      description: "When you're ready to run code, click the Execute button.",
      position: PopoverPosition.LEFT,
      alignment: PopoverPosition.START
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.EXECUTE_IDE]
  },
  {
    element: `#${IDEVIEWTABS.JDROID}`,
    popover: {
      title: 'Code faster with AI',
      description:
        'Use JDroid AI to generate code, add comments, debug, or understand logic. JDroid is free to try!',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS.JDROID}-item`],
    tabName: TAB_ICONS_NAMES.JDROID
  },
  {
    element: `#${IDEVIEWTABS.IO}`,
    popover: {
      title: 'Get inputs from users or files.',
      description:
        'Pre-set your input with STDIN, or choose "Interactive Mode" for real-time user input in the console.',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS.IO}-item`],
    tabName: TAB_ICONS_NAMES.IO
  },
  {
    element: `#${IDEVIEWTABS.LIBRARIES}`,
    popover: {
      title: 'Add libraries',
      description:
        'Add libraries here. <a class="underline" href="https://www.jdoodle.com/docs/ide/integrating-libraries-and-frameworks-in-jdoodle" rel="noopener noreferrer" target="_blank">Read the docs</a> for step-by-step instructions.',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS.LIBRARIES}-item`, BoardingElement.IDE_LIB_TAB],
    tabMobileId: IDEVIEWTABS_MOBILE.LIBRARIES,
    tabName: TAB_ICONS_NAMES.EXTERNAL_LIBRARIES
  },
  {
    element: `#${BoardingElement.IDE_SETTING}`,
    popover: {
      title: 'Customize your IDE',
      description:
        'Prefer Dark Mode, a custom color theme, or interactive mode by default? Click settings to customize',
      position: PopoverPosition.LEFT
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_SETTING]
  },
  {
    // Share Your Code
    element: `#${BoardingElement.IDE_SHARE}`,
    popover: {
      title: 'Share your code',
      description: 'Collaborate in real time by sharing projects with your team.',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_SHARE]
  },
  {
    //Need Help?
    element: `#${BoardingElement.IDE_HELP}`, // CSS selector for the "I need help" button
    popover: {
      title: 'Need help?',
      description:
        'Check out the <a class="underline" href="https://jdoodle.com/docs" target="_blank" rel="noopener noreferrer">docs</a> or <a class="underline" href="https://community.jdoodle.com" target="_blank" rel="noopener noreferrer">ask our community</a> for advice.',
      position: PopoverPosition.BOTTOM
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_HELP] // You can use a custom condition type name
  }
]

export const IdeOnboardngMobileFlow: OnboardingStep[] = [
  {
    element: `#${BoardingElement.IDE_CODE_EDITOR}`,
    popover: {
      title: 'Get started with the IDE',
      description:
        'Write your code in the IDE input window. Changes are auto-saved in the background',
      position: PopoverPosition.CENTER
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [`${IDEVIEWTABS_MOBILE.IDE}-item`]
  },
  {
    element: `#${BoardingElement.EXECUTE_IDE}`,
    popover: {
      title: 'Execute code',
      description: "When you're ready to run code, click the Execute button ",
      position: PopoverPosition.LEFT,
      alignment: PopoverPosition.START
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.EXECUTE_IDE]
  },
  {
    element: `#${IDEVIEWTABS.JDROID}-main`,
    popover: {
      title: 'Code faster with AI',
      description:
        'Use JDroid AI to generate code, add comments, debug, or understand logic. JDroid is free to try!',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS_MOBILE.JDROID}-item`],
    tabMobileId: IDEVIEWTABS_MOBILE.JDROID,
    tabName: TAB_ICONS_NAMES.JDROID
  },
  {
    element: `#${IDEVIEWTABS_MOBILE.INPUT}-main`,
    popover: {
      title: 'Get inputs from users or files.',
      description:
        'Preset input with STDIN, or choose "Interactive Mode" for real-time user input in the console',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS_MOBILE.INPUT}-item`],
    tabMobileId: IDEVIEWTABS_MOBILE.INPUT
  },
  {
    element: `#${IDEVIEWTABS.LIBRARIES}-main`,
    popover: {
      title: 'Add libraries',
      description:
        'Add libraries here. <a class="underline" href="https://www.jdoodle.com/docs/ide/integrating-libraries-and-frameworks-in-jdoodle" rel="noopener noreferrer" target="_blank">Read the docs</a> for step-by-step instructions.',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS_MOBILE.LIBRARIES}-item`, BoardingElement.IDE_LIB_TAB],
    tabMobileId: IDEVIEWTABS_MOBILE.LIBRARIES,
    tabName: TAB_ICONS_NAMES.EXTERNAL_LIBRARIES
  },
  {
    element: `#${BoardingElement.IDE_SETTING}`,
    popover: {
      title: 'Customize your IDE',
      description:
        'Prefer Dark Mode, a custom color theme, or interactive mode by default? Click settings to customize.',
      position: PopoverPosition.LEFT
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_SETTING]
  }
]
export const IdeAdvanceCompilerBoardngFlow: OnboardingStep[] = [
  {
    element: `#${BoardingElement.INSTALL_PACKAGES}`,
    popover: {
      title: 'Install packages',
      description: 'Install the dependencies listed in your package.json file.',
      position: PopoverPosition.LEFT
    },
    conditionType: [BoardingElement.INSTALL_PACKAGES]
  },
  {
    element: `#${IDEVIEWTABS.BROWSER}-main`,
    popover: {
      title: 'Live preview',
      description: 'Preview and refresh your application in the browser.',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS.BROWSER}-item`],

    tabMobileId: IDEVIEWTABS_MOBILE.OUTPUTHTML,
    tabName: TAB_ICONS_NAMES.BROWSER
  },
  {
    element: `#${IDEVIEWTABS.API_TAB}-main`,
    popover: {
      title: 'Test and call APIs',
      description:
        'Try APIs with the API Tester, and call APIs directly from your code with the <a href="https://jdoodle.com/pricing" rel="noopener noreferrer" target="_blank">Pro plan.</a>',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS.API_TAB}-item`],
    tabMobileId: IDEVIEWTABS_MOBILE.APITAB,
    tabName: TAB_ICONS_NAMES.API_TAB
  },
  {
    element: `#${IDEVIEWTABS.JDROID}`,
    popover: {
      title: 'Code faster with AI',
      description:
        'Use JDroid AI to generate code, add comments, debug, or understand logic. JDroid is free to try!',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS.JDROID}-item`],
    tabName: TAB_ICONS_NAMES.JDROID
  },
  {
    element: `#${BoardingElement.IDE_SETTING}`,
    popover: {
      title: 'Customize your IDE',
      description:
        'Prefer Dark Mode, a custom color theme, or interactive mode by default? Click settings to customize',
      position: PopoverPosition.LEFT
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_SETTING]
  },
  {
    //Need Help?
    element: `#${BoardingElement.IDE_HELP}`, // CSS selector for the "I need help" button
    popover: {
      title: 'Need help?',
      description:
        'Check out the <a class="underline" href="https://jdoodle.com/docs" target="_blank" rel="noopener noreferrer">docs</a> or <a class="underline" href="https://community.jdoodle.com" target="_blank" rel="noopener noreferrer">ask our community</a> for advice.',
      position: PopoverPosition.BOTTOM
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_HELP] // You can use a custom condition type name
  }
]

export const databaseBoardingFlow: OnboardingStep[] = [
  {
    element: `#${BoardingElement.IDE_CODE_EDITOR}`,
    popover: {
      title: 'Get started with databases',
      description: 'Write queries in the input window.',
      position: PopoverPosition.TOP
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_CODE_EDITOR, BoardingElement.IDE_DATABSE_OPEN]
  },
  {
    element: `#${IDEVIEWTABS.DB_TERMINAL}-main`,
    popover: {
      title: 'View databases from console',
      description: 'Access and manage your databases directly from here.',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS.DB_TERMINAL}-item`],
    tabMobileId: IDEVIEWTABS_MOBILE.OUTPUTHTML,
    tabName: TAB_ICONS_NAMES.DB_TERMINAL
  },
  {
    element: `#${BoardingElement.IDE_DATABASE_INFO}`,
    popover: {
      title: 'Get database information',
      description:
        'Retrieve connection details here to connect to the database. Your database connection will persist for 30 minutes after executing a query.',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_DATABASE_INFO]
  },
  {
    element: `#${BoardingElement.EXECUTE_IDE}`,
    popover: {
      title: 'Execute query',
      description: "When you're ready to run a query, click the Execute button.",
      position: PopoverPosition.LEFT,
      alignment: PopoverPosition.START
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.EXECUTE_IDE]
  },
  {
    element: `#${IDEVIEWTABS.JDROID}`,
    popover: {
      title: 'Create databases faster with AI',
      description:
        'Use JDroid AI to generate queries, help design schemas, or add comments. JDroid is free to try!',
      position: PopoverPosition.RIGHT
    },
    idType: IdeFlowType.TAB,
    conditionType: [`${IDEVIEWTABS.JDROID}-item`],
    tabName: TAB_ICONS_NAMES.JDROID
  },
  {
    //Need Help?
    element: `#${BoardingElement.IDE_HELP}`, // CSS selector for the "I need help" button
    popover: {
      title: 'Need help?',
      description:
        'Check out the <a class="underline" href="https://jdoodle.com/docs" target="_blank" rel="noopener noreferrer">docs</a> or <a class="underline" href="https://community.jdoodle.com" target="_blank" rel="noopener noreferrer">ask our community</a> for advice.',
      position: PopoverPosition.BOTTOM
    },
    idType: IdeFlowType.BUTTON,
    conditionType: [BoardingElement.IDE_HELP] // You can use a custom condition type name
  }
]

export const changeLogArray = [
  {
    header: 'December release highlights:',
    desc: [
      ' Build full-stack apps with newly added <b>frameworks</b> like React, Vue, Flask. & more.',
      ' Work on code together in <b>real-time</b> and instantly see changes.',
      'Connect your app to <b>databases</b> like MongoDB, PostgreSQL, or MySQL.',
      `Debug & <b>test APIs</b> directly within JDoodle's IDE.`
    ]
  }
]
