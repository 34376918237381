import { Terminal } from '@xterm/xterm'
import type { FitAddon } from 'xterm-addon-fit'

export interface ITerminal {
  xterm: Terminal
  id: string
  fitAddon: FitAddon
  socket: WebSocket
  history: string[]
  readonly: boolean
}

export interface IConsoleTerminal {
  xterm: Terminal | null
  fitAddon: FitAddon | null
  history: string[] | null
  socket: WebSocket | null
}

// Detect terminal's special flags 🚩
export const TerminalMsg = {
  CREATING: 'Creating terminal session...',
  INTERRUPT: '__INTERRUPT__'
}

export const ConsoleMsg = {
  START: '__START__',
  STOP: '__STOP__',
  INSTALL: '__INSTALL__'
}
