<script setup lang="ts">
import { computed, ref } from 'vue'
import { RouterLink } from 'vue-router'
import { DESKTOPNAVIGATIONIDS } from '@/utils/ide'
import { useNavStore } from '@/stores/nav.store'
import MegaMenu from '@/components/header/navigation/MegaMenu.vue'

const props = defineProps({
  mobileIdeVersion: {
    type: Boolean,
    required: false,
    default: false
  },
  isProductPage: {
    type: Boolean,
    required: false,
    default: false
  }
})

interface ILinkItem {
  title: string
  routeName: string

  categories: {
    name: string
    path: string
  }[]
}

const LinkItems: ILinkItem[] = [
  {
    title: 'Learn',
    routeName: 'integrate-online-ide-compiler-api-plugins',

    categories: [
      { name: 'Documentation', path: 'docs' },
      { name: 'Tutorials', path: 'tutorials' },
      { name: 'Blogs', path: 'blog' },
      { name: 'Webinar', path: 'webinar' },
      { name: 'Programmable Podcast', path: 'blog/category/podcast' }
    ]
  },
  {
    title: 'Use Cases',
    routeName: 'case-study',

    categories: [
      {
        name: 'Kansas University',
        path: 'case-study/kansas'
      },
      {
        name: 'iText',
        path: 'case-study/itext'
      },
      {
        name: 'Kodezi',
        path: 'case-study/kodezi'
      },
      {
        name: 'Link Edu Group',
        path: 'case-study/linkgroup'
      },
      {
        name: 'Outlier',
        path: 'case-study/outlier'
      }
    ]
  }
]
const navStore = useNavStore()

const openIndex = ref<number | null>(null)

const isCollapsedHeader = computed(() => {
  return navStore.isCollapsedHeader
})

/**
 * open dropdown
 * @param  index - The index of the dropdown to toggle.
 */
const openDropdown = (index: number) => {
  openIndex.value = index
}
/**
 * Checks if a dropdown at the specified index is open.
 * @param  index - The index of the dropdown to check.
 * @returns  - True if the dropdown at the specified index is open, otherwise false.
 */
const isOpen = (index: number) => {
  return openIndex.value === index
}
/**
 * Updating the isMarket
 */
</script>

<template>
  <div
    class="hs-dropdown-menu fixed left-[184px] hidden opacity-0 transition-[opacity,margin] delay-[250ms] hs-dropdown-open:opacity-100 hs-dropdown-open:delay-100 sm:top-24 lg:left-[126px] lg:top-10"
    v-if="props.isProductPage && !isCollapsedHeader"
  >
    <div
      class="flex w-full cursor-pointer justify-start gap-6 pt-4 sm:pl-0 sm:pt-0 lg:flex-row lg:gap-20"
    >
      <div
        class="h-15 stroke-primary-default background-quaternary inline-flex w-40 flex-col items-start justify-start rounded-[7px] border p-1.5"
      >
        <div class="w-full" v-for="(links, index) of LinkItems" :key="index">
          <div
            :id="DESKTOPNAVIGATIONIDS.SHOW_NAVIGATION_MENU_ID"
            class="hover:surface-primary-header flex w-full cursor-pointer items-center justify-between p-1.5 lg:rounded-md"
            @mouseover="openDropdown(index)"
          >
            <span class="text-[14px]">{{ links.title }}</span>
            <FontAwesomeIcon
              :icon="
                props.isProductPage && !isCollapsedHeader ? 'fa-chevron-down' : 'fa-chevron-right'
              "
              class="ml-3 mr-2 h-3 w-full sm:mr-0 sm:h-2.5 sm:w-2.5"
            />
          </div>

          <div v-if="isOpen(index)" class="mt-2 w-full">
            <div
              class="hover:surface-primary-header ml-2 mt-1 px-3 py-2 lg:rounded-md"
              v-for="(category, index) of links.categories"
              :key="index"
            >
              <RouterLink :to="`/${category.path}`">
                <ul class="m-0 list-none p-0">
                  <li class="flex items-center">
                    <span class="text-[13px] leading-snug">
                      {{ category.name }}
                    </span>
                  </li>
                </ul>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MegaMenu v-else :mobileIdeVersion="props.mobileIdeVersion">
    <div
      class="flex w-full flex-col justify-start gap-6 pt-4 sm:pl-0 sm:pt-0 lg:flex-row lg:gap-20"
    >
      <div v-for="(links, index) of LinkItems" :key="index" class="pr-8">
        <div class="flex flex-col gap-4 text-base">
          <h3
            :class="[
              'text-primary font-medium uppercase tracking-wider dark:text-text-primary-dark',
              {
                'text-sm': props.mobileIdeVersion
              }
            ]"
          >
            {{ links.title }}
          </h3>
          <div v-for="(category, index) of links.categories" :key="index">
            <RouterLink
              v-if="category.name === 'Documentation'"
              :class="[
                'text-primary hover:color-secondary-600 font-light',
                {
                  'text-sm': props.mobileIdeVersion
                },
                {
                  'lg:p-normal text-sm': !props.mobileIdeVersion
                }
              ]"
              :to="`/docs`"
              >Documentation</RouterLink
            >
            <RouterLink
              v-else
              :class="[
                'text-primary hover:color-secondary-600 text-justify font-light sm:block',
                {
                  'text-sm': props.mobileIdeVersion
                },
                {
                  'lg:p-normal text-sm': !props.mobileIdeVersion
                }
              ]"
              :to="`/${category.path}`"
            >
              {{ category.name }}
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </MegaMenu>
</template>
