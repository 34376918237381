<script setup lang="ts">
import { computed } from 'vue'
import { useNavStore } from '@/stores/nav.store'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { AUTHMODELHS } from '@/utils/models'

import { storeToRefs } from 'pinia'

import Button from '@/components/atoms/button/index.vue'

import ProfileDropdownNew from '@/components/header/auth/profile/ProfileDropdownNew.vue'

const props = defineProps({
  isIdeMobileMenu: {
    type: Boolean,
    required: false,
    default: false
  }
})

const authStore = useAuthStore()
const navStore = useNavStore()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { isUserloggedIn, firstName, notificationCount } = storeToRefs(authStore)

const ideStore = useIdeStore()

const isIdeView = computed(() => {
  return ideStore.isIdeView
})

const isCollapsedHeader = computed(() => {
  return navStore.isCollapsedHeader
})

const isMarketingPage = computed(() => {
  return navStore.isMarketingPage
})
</script>

<template>
  <div
    class="flex flex-col gap-3.5 lg:gap-5"
    :class="isIdeView ? 'sm:flex-row' : 'sm:flex-row sm:py-0'"
  >
    <!-- Profile -->
    <div
      v-if="!isUserloggedIn"
      class="relative flex items-center pr-1"
      :class="[{ 'hidden lg:block': !isIdeView }, { 'sm:block': !isMarketingPage }]"
    >
      <Button
        color="secondary"
        data-testid="LoginButton"
        variant="outline"
        size="small"
        :data-hs-overlay="`#${AUTHMODELHS.LOGIN}`"
        class="h-fit py-2"
      >
        <div
          :class="[
            'flex items-center gap-3 text-new-secondary-700',
            {
              'text-[14px]': isIdeView && !props.isIdeMobileMenu //IdeView Main Nav
            },
            {
              'text-md font-medium': props.isIdeMobileMenu //Mobile IdeView sideNav
            },
            {
              'text-md font-medium lg:text-sm lg:font-normal': !isIdeView
            }
          ]"
        >
          <FontAwesomeIcon
            icon="fa-user"
            class="hidden"
            :class="isIdeView ? 'lg:block' : 'lg:block'"
          />
          Login
        </div>
      </Button>
    </div>

    <div
      v-if="isUserloggedIn && firstName !== null"
      class="relative hidden items-center lg:flex lg:pr-1"
      :class="{ 'sm:flex': isIdeView || !isMarketingPage }"
    >
      <ProfileDropdownNew v-if="!isCollapsedHeader" />

      <!-- <span
              v-if="notificationCount && notificationCount > 0"
              class="absolute right-0 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center rounded-full bg-btn-secondary px-1.5 py-0.5 text-xs font-medium text-btn-secondary-dark dark:bg-btn-secondary-dark dark:text-btn-secondary"
            >
              {{ notificationCount > 99 ? '99+' : notificationCount }}
            </span> -->
    </div>
  </div>
</template>
