import { useAdvanceCompilerStore } from '@/stores/advanceCompiler.store'
import { useIdeStore } from '@/stores/ide.store'

/**
 * @returns socket
 */
const createConsoleWebSocketService = () => {
  let hostname = ''
  if (process.env.NODE_ENV === 'development') {
    const proxyEnv = import.meta.env.VITE_PROXY_BACKEND_URL as string
    hostname = proxyEnv?.replace(/^https?:\/\/|\/$/g, '')
  } else {
    hostname = window.location.hostname
  }

  const socket = new WebSocket(
    `wss://${hostname}/adv-engine/console?lang=${useIdeStore().ideMeta?.language}`
  )

  return socket
}

/**
 * Establish a WebSocket receivers
 */
const connectConsoleReceivers = () => {
  const socket = useAdvanceCompilerStore().console?.socket
  const advCompilerStore = useAdvanceCompilerStore()

  if (socket) {
    socket.onmessage = function (event) {
      const xterm = advCompilerStore.console?.xterm

      xterm?.write(event.data === '$' ? event.data + ' ' : event.data)
      useAdvanceCompilerStore().appendConsoleHistory(event.data)
    }
  }
}

/**
 * Send a message via WebSocket
 * @param message - The message to send
 */
const sendMessage = (message: string) => {
  const socket = useAdvanceCompilerStore().console?.socket

  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.send(message)
  } else if (socket && socket.readyState === WebSocket.CONNECTING) {
    socket.addEventListener('open', () => socket.send(message), { once: true })
  }
}

/**
 * Close the WebSocket connection
 */
const disconnectConsoleWebSocket = () => {
  const socket = useAdvanceCompilerStore().console?.socket
  if (socket) {
    socket.close()
    useAdvanceCompilerStore().setConsoleSocketInstance(null)
  }
}

export {
  createConsoleWebSocketService,
  connectConsoleReceivers,
  sendMessage,
  disconnectConsoleWebSocket
}
