import {
  CURRENT_RUNNING_WS_SERVICE,
  INIT_NEXT_ACTION
} from '@/components/organisation/enum/advanceCompiler.enums'
import { ConsoleMsg } from '@/components/organisation/interface/IAdvanceCompiler'
import {
  connectConsoleReceivers,
  createConsoleWebSocketService,
  sendMessage
} from '@/services/consolews.service'
import advanceWsService from '@/services/ide/advancews.service'
import projectsService from '@/services/ide/projects.service'

import {
  createPingWebSocketService,
  disconnectPingWebSocketService
} from '@/services/pingws.service'
import utilModelsService from '@/services/util.models.service'
import { useAdvanceCompilerStore } from '@/stores/advanceCompiler.store'
import { useIdeStore } from '@/stores/ide.store'
import axios from 'axios'
import stripAnsi from 'strip-ansi'

export interface IExecuteRequestAdvanceCompiler {
  lang: string | null
  type: string | null
  projectId: number | null
  projectKey: string | boolean | number
  shareId: number | null
  isInstant: boolean
}

export interface IinitPodsRequest {
  projectKey: string | number
  projectId: number
  language: string
}

interface Pod {
  ip: string
  port: string
  lastAccess: number
  subdomain: string
  language: string
  env: string | null
  projectId: number
  projectKey: number
}

interface PodResponse {
  identifier: string
  signature: string
  nextAction: INIT_NEXT_ACTION
  pod: Pod | null
}

/**
 * Processes a message by cleaning ANSI codes, checking for a subdomain keyword,
 * and updating the `advanceCompilerStore` with relevant information.
 *
 * If the message starts with the keyword `subdomain:`, the subdomain value is
 * extracted and stored. The function also enables logging messages and updates
 * the log messages in the store.
 * @param message - The input message to process.
 */
const setMessages = (message: string) => {
  const eraseInLinePattern = new RegExp(String.fromCharCode(27) + '\\[K', 'g')
  const keyword = 'subdomain:'
  if (eraseInLinePattern.test(message)) {
    const updatedMessage = message.replace(eraseInLinePattern, '')

    const finalMessage = stripAnsi(updatedMessage)
    useAdvanceCompilerStore().setLogMessages(finalMessage, true)
  } else {
    const script = stripAnsi(message)
    if (script.startsWith(keyword)) {
      const subdomainValue = script.substring(keyword.length)
      useAdvanceCompilerStore().setSubDomainUrl(subdomainValue)
    }
    useAdvanceCompilerStore().setLogMessages(script)
  }

  useAdvanceCompilerStore().setStartLoggingMessages(true)
}
/**
 * Initializes the pods for the advance compiler by gathering necessary data
 * from the IDE and compiler stores, constructing a request, and sending it
 * via WebSocket.
 */
const initPodsAdvanceCompiler = () => {
  const language = useIdeStore().routeMeta?.language
  const projectKey = useAdvanceCompilerStore().projectKeyAdvCompiler
  const projectId = useAdvanceCompilerStore().projectIdAdvCompiler
  const sessionId = useAdvanceCompilerStore().sessionId
  const kurukkuKuriId = useAdvanceCompilerStore().kurukkuKuriId
  const requestData: any = {
    destination: `/box/${CURRENT_RUNNING_WS_SERVICE.INIT}`,
    message: `{"language": "${language}","projectKey": ${projectKey}, "projectId":${projectId}}`,
    message_type: 'execute',
    sessionId: sessionId,
    signature: kurukkuKuriId
  }

  useAdvanceCompilerStore().setLogMessages([])
  useAdvanceCompilerStore().setCurrentWsServiceAdvCompiler(CURRENT_RUNNING_WS_SERVICE.INIT)
  advanceWsService.sendWs(requestData)
}

/**
 * Starts or initializes pods for the advance compiler, depending on the state of pod initialization.
 *
 * It also clears log messages, updates the WebSocket service status, and sets various flags
 * (such as pod initiation and loading status) in the advance compiler store. If there is an active
 * iframe source in the IDE store, it resets that source as well.
 */
const startPods = async () => {
  const destination =
    useAdvanceCompilerStore().isPodInitializationHasErrors &&
    useAdvanceCompilerStore().currentWsServiceAdvCompiler == CURRENT_RUNNING_WS_SERVICE.INIT
      ? CURRENT_RUNNING_WS_SERVICE.INIT
      : CURRENT_RUNNING_WS_SERVICE.START

  if (destination == CURRENT_RUNNING_WS_SERVICE.INIT) {
    useAdvanceCompilerStore().setCurrentWsServiceAdvCompiler(CURRENT_RUNNING_WS_SERVICE.INIT)
  } else {
    useAdvanceCompilerStore().setCurrentWsServiceAdvCompiler(CURRENT_RUNNING_WS_SERVICE.START)
  }
  if (useIdeStore().currentIFrameSourceBrowser) {
    useIdeStore().setCurrentIframeSourceBrowser('')
  }

  if (useAdvanceCompilerStore().console.socket?.readyState === WebSocket.CLOSED) {
    const socket = createConsoleWebSocketService()
    useAdvanceCompilerStore().setConsoleSocketInstance(socket)
    connectConsoleReceivers()
  }

  sendConsoleMessage(ConsoleMsg.START)
}

/**
 * Stops the running pods for the advance compiler by sending a request to the WebSocket service.
 *
 * It also updates the WebSocket service status to "STOP", clears log messages,
 * and adjusts relevant flags in the advance compiler store (indicating that the pods have been
 * stopped and the starting process is no longer active).
 */
const stopPods = async () => {
  useAdvanceCompilerStore().setCurrentWsServiceAdvCompiler(CURRENT_RUNNING_WS_SERVICE.STOP)
  useAdvanceCompilerStore().setLogMessages([])

  if (useIdeStore().currentIFrameSourceBrowser) {
    useIdeStore().setCurrentIframeSourceBrowser('')
  }

  if (useAdvanceCompilerStore().console.socket?.readyState === WebSocket.CLOSED) {
    const socket = createConsoleWebSocketService()
    useAdvanceCompilerStore().setConsoleSocketInstance(socket)
    connectConsoleReceivers()
  }

  sendConsoleMessage(ConsoleMsg.STOP)
}

/**
 * Installs the necessary library pods for the advance compiler by sending a request to the WebSocket service.
 *
 * It also updates the WebSocket service status to "INSTALL_LIBRARY" and
 * clears any existing log messages.
 */
const installLibraryPods = async () => {
  useAdvanceCompilerStore().setCurrentWsServiceAdvCompiler(
    CURRENT_RUNNING_WS_SERVICE.INSTALL_LIBRARY
  )
  useAdvanceCompilerStore().setLogMessages([])

  if (useIdeStore().currentIFrameSourceBrowser) {
    useIdeStore().setCurrentIframeSourceBrowser('')
  }

  sendConsoleMessage(ConsoleMsg.INSTALL)
}

/**
 * Sends a message to the console WebSocket service
 * @param msg - The message to send
 */
const sendConsoleMessage = (msg: string) => {
  if (useAdvanceCompilerStore().console.socket?.readyState === WebSocket.CLOSED) {
    const socket = createConsoleWebSocketService()
    useAdvanceCompilerStore().setConsoleSocketInstance(socket)
    connectConsoleReceivers()
  }
  sendMessage(msg)
}

/**
 * Asynchronously checks the status of existing pods by sending a POST request
 * to the `/adv-engine/box/init` endpoint with the provided initialization request data.
 * @param  request - The initialization request object containing the necessary data.
 * @returns A promise that resolves with the response data from the API.
 */
const checkExistingPods = async (request: IinitPodsRequest) => {
  const res = await axios.post('/adv-engine/box/init', request)
  return res.data
}
/**
 * Handles the reinitialization of the IDE by updating the advance compiler and IDE stores
 * with the information from the provided pod response.
 * @param response The response object containing pod data to be used for reinitialization.
 */
const handleReinitIde = (response: PodResponse) => {
  useAdvanceCompilerStore().setProjectKeyAdvCompiler(response.pod?.projectKey || null)
  useAdvanceCompilerStore().setIsPodInitializationCompleted(true)
  useAdvanceCompilerStore().setServerErrorMessages(null)
  useAdvanceCompilerStore().setSubDomainUrl(response.pod?.subdomain || null)
  useIdeStore().setProjectKey(response.pod?.projectKey?.toString() || '')
}
/**
 * Handles the redirection process based on user confirmation after receiving a pod response.
 * @param response The response object containing pod data for the redirection process.
 */
const handleRedirect = async (response: PodResponse) => {
  if (!useIdeStore().isAdvancedCompiler) return
  const userResponse = await openConfirmationModel()
  if (!userResponse) {
    initPodsAdvanceCompiler()
  } else {
    const payloadRequest = {
      id: response.pod?.projectId?.toString(),
      multipleFile: true
    }
    await projectsService.loadProject(payloadRequest, response.pod?.language).then(async (res) => {
      await projectsService.openProject(res.project)
    })

    useIdeStore().setProjectKey(response.pod?.projectKey?.toString() || '')
  }
}

/**
 * Opens a confirmation modal to prompt the user regarding pod actions.
 * @returns  A promise that resolves to true if the user confirmed the action, false otherwise.
 */
const openConfirmationModel = async () => {
  const language = useIdeStore().isLangDisplayName

  const confirmModal = {
    title: `Attention: A ${language} project is already running`,
    subtitle: `A ${language} project is already running in JDoodle. Your plan only allows for one project to run at a time. <br/><br/> Creating a new project will stop your existing project running, but your project files are safe. <br/><br/> Select an option to continue:`,
    retunHs: undefined,
    yesBtnText: `Open Existing Project`,
    noBtnText: `Create New Project`,
    hideCloseBtn: true
  }

  try {
    await utilModelsService.confirmPromise(
      confirmModal.title,
      confirmModal.subtitle,
      confirmModal.retunHs,
      confirmModal.yesBtnText,
      confirmModal.noBtnText,
      confirmModal.hideCloseBtn,
      'w-full sm:w-auto',
      'w-full sm:w-auto'
    )
    return true
  } catch {
    return false
  }
}

/**
 * Asynchronously waits for 5 second and then initializes the advance compiler service.
 */
const callInitAgain = async () => {
  await new Promise((resolve) => setTimeout(resolve, 5000))
  initAdvanceCompiler()
}

/**
 * Initializes the advance compiler for the current project by checking existing pods
 * and determining the next action based on the response.
 */
const initAdvanceCompiler = async () => {
  if (useIdeStore().project && useIdeStore().project?.id) {
    const projectKey = useIdeStore().projectKey as string
    const projectId = useIdeStore().project?.id as number
    const projectLanguage = useIdeStore().isLanguage as string

    if (useIdeStore().isAdvancedCompiler) {
      if (projectId && projectKey && projectLanguage) {
        await cleanAdvanceCompilerStore()
        const request: IinitPodsRequest = {
          projectKey: projectKey,
          projectId: projectId,
          language: projectLanguage
        }
        createPingWebSocketService()
        useAdvanceCompilerStore().setProjectKeyAdvCompiler(projectKey)
        useAdvanceCompilerStore().setProjectIdAdvCompiler(projectId)
        const response = await checkExistingPods(request)

        if (response) {
          useAdvanceCompilerStore().setSessionId(response?.identifier)
          useAdvanceCompilerStore().setkurukkuKuriId(response?.signature)
          useAdvanceCompilerStore().setInitNextAction(response?.nextAction)
          switch (response.nextAction) {
            case INIT_NEXT_ACTION.INIT_POD:
              initPodsAdvanceCompiler()
              break
            case INIT_NEXT_ACTION.RE_INIT_IDE:
              if (response.pod) {
                handleReinitIde(response)
              }
              break
            case INIT_NEXT_ACTION.READY:
              if (response.pod) {
                handleReinitIde(response)
              }
              break
            case INIT_NEXT_ACTION.REDIRECT: {
              if (response.pod) {
                handleRedirect(response)
              }
              break
            }
            case INIT_NEXT_ACTION.KILL: {
              initPodsAdvanceCompiler()
              break
            }
            case INIT_NEXT_ACTION.WAIT: {
              callInitAgain()
              break
            }

            default:
              break
          }
        }
      }
    }
  }
}
/**
 * Cleans up the advance compiler store by closing the WebSocket connection
 * and resetting the store state.
 */
const cleanAdvanceCompilerStore = async () => {
  advanceWsService.closeWebSocketConnection()
  disconnectPingWebSocketService()
  useAdvanceCompilerStore().$reset()
}

export default {
  startPods,
  stopPods,
  setMessages,
  initPodsAdvanceCompiler,
  checkExistingPods,
  installLibraryPods,
  initAdvanceCompiler,
  cleanAdvanceCompilerStore
}
