<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { DESKTOPNAVIGATIONIDS } from '@/utils/ide'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { useProjectManager } from '@/stores/projectManager.store'
import { useAdvanceCompilerStore } from '@/stores/advanceCompiler.store'
import { useAdvanceCompilerDatabaseStore } from '@/stores/advanceCompilerDatabase.store'
import tabsSettingIcon from '@/assets/images/ide/dots-vertical.svg'
import arrowMaximize from '@/assets/images/ide/arrows-diagonal.svg'
import fileIcon from '@/assets/images/ide/file-code.svg'
import renameIcon from '@/assets/images/ide/pencil.svg'
import saved from '@/assets/images/ide/cloud.svg'
import refresh from '@/assets/images/ide/refresh.svg'
import editorService from '@/services/ide/editor.service'
import projectsService, { AUTO_SAVING_ANIMATION_STATUS } from '@/services/ide/projects.service'
import ideService from '@/services/ide/ide.service'
import SideBarTooltip from '@/components/ide/ide/SettingsSideBarTooltip.vue'
import { HttpStatusCode } from 'axios'
import PencilOff from '@/assets/images/ide/pencil-off.svg?component'

const route = useRoute()
const ideStore = useIdeStore()
const authStore = useAuthStore()
const projectManagerStore = useProjectManager()
const advCompilerStore = useAdvanceCompilerStore()
const advanceCompilerDatabaseStore = useAdvanceCompilerDatabaseStore()

const showMoreSettings = ref<boolean>(false)
const autoSaveTimeOut = ref<NodeJS.Timeout | number | null>(null)
const isProjectNameEditing = ref<boolean>(false)
const updateProjectName = ref<string>('')
const isLoading = ref<boolean>(false)
const renameInputRef = ref<HTMLInputElement | null>(null)

const showProjectName = computed(() => {
  return ideStore.project && ideStore.project?.name
})
const projectName = computed(() => {
  return ideStore.project?.name
})
const showAutoEnabled = computed(() => {
  return projectManagerStore.showAutoSavingStatus
})

const showInitPodView = computed(() => {
  return ((route.meta.advanceCompiler && !advCompilerStore.isPodInitializationCompleted) ||
    (route.meta.advanceCompilerDatabase &&
      !advanceCompilerDatabaseStore.isPodInitializationCompleted)) as boolean
})

const showPencilOffIcon = computed(() => {
  if (authStore.isCreateProjectLimitExceeded) {
    return true
  } else {
    if (
      authStore.isCreateProjectLimitReached &&
      !projectManagerStore.comingFromProjectManager &&
      !projectManagerStore.comingFromStartCodingModal &&
      !ideService.isProjectExist(route?.meta?.isAdvanced, route?.meta?.language)
    ) {
      return true
    } else {
      return false
    }
  }
})

/**
 * toggle ide full screen
 */
const toggleIdeFullScreen = async () => {
  showMoreSettings.value = !showMoreSettings.value
  ideStore.ideFullScreen = true
  ideStore.setIdeFullScreenMaximizedState(true)
  await new Promise((resolve) => setTimeout(resolve, 10))
  editorService.resizeOutputEditor()
}

/**
 * show menu on mouse enter
 */
const handleMouseClick = () => {
  showMoreSettings.value = !showMoreSettings.value
}

/**
 * @param event Click event
 * If clicking outside of dropdown div, dropdown will disappear
 */
const clickEventHandler = (event: any) => {
  if (event.target.id != DESKTOPNAVIGATIONIDS.SHOW_TAB_SETTINGS_ICON_IDE) {
    showMoreSettings.value = false
  }
}

/**
 * Toggles the rename project functionality.
 * @param value - The new state to set for renaming the project.
 * @param [event] - Optional keyboard event that triggered the toggle.
 */
const toggleRenameProject = (value: boolean, event?: KeyboardEvent) => {
  isProjectNameEditing.value = value
  if (isProjectNameEditing.value) {
    updateProjectName.value = projectName.value
    nextTick(() => renameInputRef.value?.focus())
  } else {
    renameInputRef.value?.blur()
  }
  if (event?.key === 'Enter') {
    if (updateProjectName.value.trim() === '') {
      isProjectNameEditing.value = false
      return
    }
    isLoading.value = true

    if (updateProjectName.value.trim() !== projectName.value) {
      projectsService
        .renameProjectName(updateProjectName.value)
        .then((response) => {
          if (response.status === HttpStatusCode.Ok) {
            ideStore.project.name = updateProjectName.value
            isProjectNameEditing.value = false
            isLoading.value = false
          }
        })
        .catch(() => {
          isLoading.value = false
          isProjectNameEditing.value = false
        })
    }
  }
}

onMounted(() => {
  window?.addEventListener('click', clickEventHandler)
})

onUnmounted(() => {
  window?.removeEventListener('click', clickEventHandler)
})

watch(
  () => showAutoEnabled.value,
  () => {
    if (showAutoEnabled.value === AUTO_SAVING_ANIMATION_STATUS.SAVED) {
      if (autoSaveTimeOut.value) clearTimeout(autoSaveTimeOut.value)
      autoSaveTimeOut.value = setTimeout(() => {
        projectManagerStore.setShowAutoSavingStatus(null)
      }, 1500)
    }
  }
)
</script>

<template>
  <div class="flex h-full min-w-max flex-col justify-center ps-1 sm:pl-0 lg:max-w-[160px]">
    <div class="flex flex-row items-center justify-center">
      <div class="flex w-full items-center overflow-hidden">
        <div class="flex w-fit items-center pr-1">
          <img v-if="showAutoEnabled === null" :src="fileIcon" class="h-[18.2px] w-[18.2px]" />
          <img
            v-else-if="showAutoEnabled === AUTO_SAVING_ANIMATION_STATUS.SAVING"
            :src="refresh"
            class="rotate-infinite h-[18.2px] w-[18.2px]"
          />
          <img
            v-else-if="showAutoEnabled === AUTO_SAVING_ANIMATION_STATUS.SAVED"
            :src="saved"
            class="h-[18.2px] w-[18.2px]"
          />
        </div>
        <div
          class="hs-tooltip flex flex-col items-start justify-center"
          :class="
            !showProjectName && showAutoEnabled === null ? 'w-fit pr-2' : 'w-[50px]  md:w-fit'
          "
        >
          <Transition name="saving" mode="out-in">
            <div
              class="block w-full max-w-[150px] gap-2 truncate text-[13px] sm:max-w-[160px] md:max-w-max"
              v-if="showAutoEnabled === null"
            >
              <h2
                v-if="showProjectName && !isProjectNameEditing"
                class="leaning-loose hs-tooltip-toggle block w-full max-w-[150px] gap-2 truncate text-[13px] sm:max-w-[160px] md:max-w-max"
                :class="{ 'max-w-[70px]': ideStore.currentMobileTab != null }"
              >
                {{
                  isLoading
                    ? 'updating...'
                    : projectName.length > 30
                    ? `${projectName.slice(0, 30)}...`
                    : projectName
                }}
                <SideBarTooltip :content="projectName" />
              </h2>
              <input
                v-else-if="isProjectNameEditing"
                ref="renameInputRef"
                v-model="updateProjectName"
                @blur="toggleRenameProject(false)"
                @keyup.enter="(event: KeyboardEvent) => toggleRenameProject(false, event as KeyboardEvent)"
                class="stroke-primary-default bg-primary w-full rounded-md border p-0.5 pl-2 text-[13px] font-normal leading-snug outline-none dark:bg-inherit"
              />
            </div>
            <div v-else-if="showAutoEnabled === AUTO_SAVING_ANIMATION_STATUS.SAVING">
              <h2 class="text-[13px]">Saving...</h2>
            </div>
            <div v-else-if="showAutoEnabled === AUTO_SAVING_ANIMATION_STATUS.SAVED">
              <h2 class="text-[13px]">Saved</h2>
            </div>
          </Transition>
          <h2
            v-if="!showProjectName && showAutoEnabled === null"
            class="leaning-loose block w-full text-[13px]"
          >
            New Project <PencilOff v-if="showPencilOffIcon" class="mb-0.5 inline-block h-4" />
          </h2>
        </div>
      </div>

      <Transition name="fade">
        <div v-if="showAutoEnabled === null" class="relative">
          <div class="flex items-center justify-center">
            <button :disabled="showInitPodView" @click="handleMouseClick">
              <img
                :id="DESKTOPNAVIGATIONIDS.SHOW_TAB_SETTINGS_ICON_IDE"
                :src="tabsSettingIcon"
                class="h-4.5 w-4.5"
              />
            </button>
          </div>
          <div
            :id="DESKTOPNAVIGATIONIDS.SHOW_TAB_SETTINGS_ICON_IDE"
            v-if="showMoreSettings"
            @click="handleMouseClick"
            class="stroke-secondary-default surface-secondary-base absolute inline-flex h-auto max-h-[86px] w-[120px] flex-col items-center justify-start rounded-[7px] border p-1.5 shadow"
          >
            <div class="inline-flex flex-col items-center justify-start gap-1 rounded p-1">
              <div
                class="inline-flex flex-col items-center justify-start rounded p-1.5 hover:bg-new-neutral-100 dark:hover:bg-new-neutral-700/50"
              >
                <button
                  @click="toggleIdeFullScreen"
                  class="flex cursor-pointer items-end justify-between gap-1 pr-[23px]"
                >
                  <img :src="arrowMaximize" class="h-5 w-5" />
                  <div class="text-xs font-normal leading-snug">Maximize</div>
                </button>
              </div>

              <div
                v-if="showProjectName && !isProjectNameEditing"
                @click="toggleRenameProject(true)"
                class="inline-flex flex-col items-center justify-start rounded p-1.5 hover:bg-new-neutral-100 dark:hover:bg-new-neutral-700/50"
              >
                <button
                  class="flex w-full cursor-pointer items-center justify-between gap-1 pr-[23px]"
                >
                  <div class="h-6 w-6">
                    <img :src="renameIcon" />
                  </div>
                  <div class="text-xs font-normal leading-snug">Rename</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
<style scoped>
.fade-enter-active {
  transition: opacity 3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.saving-enter-active,
.saving-leave-active {
  transition: all 0.5s ease-out;
}

.saving-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.saving-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.rotate-infinite {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
