import {
  WS_ERROR_CODES,
  WS_PROGRESS,
  WS_STATUS_CODE
} from '@/components/organisation/enum/advanceCompiler.enums'
import advanceCompilerDatabaseService from '@/services/ide/advanceCompilerDatabase.service'
import tabsService from '@/services/ide/tabs.service'
import recaptchaService from '@/services/recaptcha.service'
import { useAdvanceCompilerStore } from '@/stores/advanceCompiler.store'
import { useAdvanceCompilerDatabaseStore } from '@/stores/advanceCompilerDatabase.store'
import { useIdeStore } from '@/stores/ide.store'
import { useTabStore } from '@/stores/tabs.store'
import { SERVER_ERROR } from '@/utils/ide'
import { IDEVIEWTABS_MOBILE, TAB_ICONS_NAMES } from '@/utils/tabs'

let socketClient: WebSocket | null = null

/**
 * Handles the event when the WebSocket connection fails.
 *
 * This function updates the state of the socket connection to `false`
 * and cleans up the WebSocket client. If the `socketClient` is
 * defined, it removes the message event handler and sets the client to `null`.
 *
 */
const onWsConnectionFailed = () => {
  useAdvanceCompilerStore().setSocketConnected(false)
  if (socketClient) {
    socketClient.onmessage = null
    socketClient = null
  }
}

/**
 * Closes the WebSocket connection if it is open or connecting.
 *
 * This function nullifies the message, open, error, and close event handlers
 * for the `socketClient`. If the connection is open or in the process of
 * connecting, it initiates the closing process and then sets the `socketClient`
 * to `null`.
 *
 */
const closeWebSocketConnection = () => {
  if (socketClient) {
    socketClient.onmessage = null
    socketClient.onopen = null
    socketClient.onerror = null
    socketClient.onclose = null

    if (
      socketClient.readyState === WebSocket.OPEN ||
      socketClient.readyState === WebSocket.CONNECTING
    ) {
      socketClient.close()
    }

    socketClient = null
  }
}

/**
 * Handles common error scenarios by updating the store with specific error messages and states.
 * @param store - The store instance that will be updated with error details and status.
 * @param errorMessage - The message to set as the server error message in the store.
 * @param initHasError - A boolean indicating whether the pod initialization has errors.
 * @param initComplete - A boolean indicating whether the pod initialization is completed.
 */
const handleCommonError = (
  store: any,
  errorMessage: string,
  initHasError: boolean,
  initComplete: boolean
) => {
  store.setServerErrorMessages(errorMessage)
  store.setIsPodInitializationHasErrors(initHasError)
  store.setIsPodInitializationCompleted(initComplete)
  store.setLogExecuteQueryResult(null)
  store.setExecuteQueryResponse(null)
}

/**
 * Handles different status codes and performs actions based on them
 * @param statusCode The status code received
 * @param errorCode The error code if any
 * @param message The full message received
 */
const handleStatusCodes = async (statusCode: string, errorCode: string, message: any) => {
  const store = useAdvanceCompilerDatabaseStore()

  switch (errorCode) {
    case WS_ERROR_CODES.INVALID_PROJECT_KEY:
    case WS_ERROR_CODES.INTERNAL_SERVER_ERROR:
    case WS_ERROR_CODES.CREATE_POD_TIMEOUT:
    case WS_ERROR_CODES.INVALID_DESTINATION:
    case WS_ERROR_CODES.ROBOT_CHECK_INVALID:
      handleCommonError(store, SERVER_ERROR, true, true)
      break

    case WS_ERROR_CODES.ONE_MAXIMUM_BOX_LIMIT:
    case WS_ERROR_CODES.POD_READY:
      handleCommonError(store, message.message, false, true)
      break

    case WS_ERROR_CODES.ACCOUNT_NOT_FOUND:
    case WS_ERROR_CODES.POD_NOT_READY:
    case WS_ERROR_CODES.RUNBOX_NOT_FOUND:
      handleCommonError(store, SERVER_ERROR, false, true)
      break

    case WS_ERROR_CODES.POD_CREATION_ONGOING:
      handleCommonError(store, SERVER_ERROR, false, true)
      break

    default:
      break
  }

  if (message.status === WS_PROGRESS.COMPLETE) {
    store.setSocketCompleted(true)
    store.setServerErrorMessages(null)
    store.setStartLoggingMessages(false)
    store.setIsPodInitializationCompleted(true)
    store.setIsPodInitializationInProgress(false)
    store.setIsPodInitializationHasErrors(false)
    store.setLoadingPods(false)
  } else if (statusCode === WS_STATUS_CODE.OK) {
    store.setSocketCompleted(false)
    store.setServerErrorMessages(null)
    store.setIsPodInitializationInProgress(true)
    store.setIsPodInitializationHasErrors(false)
    advanceCompilerDatabaseService.setMessages(message.output)
  }
}

/**
 * Handles an incoming WebSocket message event by parsing the message,
 * @param event - The WebSocket message event containing the data.
 */
const onWsMessageReceived = (event: MessageEvent) => {
  const message = JSON.parse(event.data)

  const statusCode = message.statusCode
  const errorCode = message.errorCode

  // Handle specific status codes and sequences
  handleStatusCodes(statusCode, errorCode, message)
}

/**
 * Initiates a WebSocket connection to the advanced engine service.
 *
 * This function checks if a WebSocket client already exists and is in an
 * open or connecting state. If so, it returns early without establishing
 * a new connection. Otherwise, it creates a new WebSocket client, sets up
 * event handlers for connection open, message receipt, closure, and errors.
 * @param requestData - The data to be sent upon a successful
 * connection. Can be of any type or `null` if no data is to be sent.
 */
const initiateWsConnection = (requestData: any | null) => {
  if (
    socketClient &&
    (socketClient.readyState === WebSocket.OPEN || socketClient.readyState === WebSocket.CONNECTING)
  ) {
    return
  }

  if (process.env.NODE_ENV === 'development') {
    const proxyEnv = (import.meta.env.VITE_PROXY_BACKEND_URL as string) || undefined
    const extractedPortEnv = proxyEnv?.replace('https://', '').replace(/\/$/, '') || ''
    socketClient = new WebSocket(`wss://${extractedPortEnv}/adv-engine/ws`)
  } else {
    socketClient = new WebSocket(`wss://${window.location.hostname}/adv-engine/ws`)
  }
  /**
   *
   */
  socketClient.onopen = () => {
    useAdvanceCompilerStore().setSocketConnected(true)
    socketClient?.send(JSON.stringify(requestData))
  }

  socketClient.onmessage = onWsMessageReceived

  socketClient.onclose = onWsConnectionFailed
  /**
   * error catching
   */
  socketClient.onerror = () => {
    onWsConnectionFailed()
  }
}

/**
 * Polls the `useIdeStore().isTabsUpdating` flag at regular intervals.
 * When the flag becomes true, it executes the `tabsService.updateTabVisiblity` function.
 * If the flag is true, it will continue polling every `interval` milliseconds.
 * @param count - The current recursion depth, used to limit the number of polling attempts. Defaults to 0.
 * @returns  - Resolves when the flag becomes true and the tab visibility is updated.
 */
const checkTabsUpdating = async (count: number = 0) => {
  if (!useIdeStore().isIdeView || count > 8) return
  if (!useTabStore().isTabsUpdating) {
    if (window.innerWidth < 640) {
      useIdeStore().setMobileTab(IDEVIEWTABS_MOBILE.DB_TERMINAL)
      await new Promise((resolve) => setTimeout(resolve, 1000))
    } else {
      tabsService.updateTabVisiblity(TAB_ICONS_NAMES.DB_TERMINAL)
    }
  } else {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    await checkTabsUpdating(count + 1)
  }
}

/**
 * Sends data via an established WebSocket connection after verifying
 * reCAPTCHA.
 *
 * This function first calls the `recaptchaService` to ensure that the
 * action is validated. Upon successful validation, it updates the tab
 * visibility to the advance IDE view. If the WebSocket client is not
 * established or is not in an open state, it initiates a new WebSocket
 * connection. If the connection is open, it sends the provided request
 * data.
 * @param requestData - The data to be sent via the WebSocket. Can
 *   be of any type.
 * @returns A promise that resolves when the data has been
 *   sent or when a new WebSocket connection has been initiated.
 */
const sendWs = async (requestData: any) => {
  const store = useAdvanceCompilerDatabaseStore()
  await recaptchaService.callViaCaptcha().then(async () => {
    store.setSocketCompleted(false)
    await checkTabsUpdating()
    if (!socketClient || socketClient.readyState !== WebSocket.OPEN) {
      initiateWsConnection(requestData)
    } else {
      socketClient?.send(JSON.stringify(requestData))
    }
  })
}

export default { initiateWsConnection, sendWs, closeWebSocketConnection }
