<script setup lang="ts">
import { computed, watch, onMounted, onUnmounted } from 'vue'
import { IDEVIEWTABS_MOBILE } from '@/utils/tabs'
import { BoardingElement } from '@/utils/ideBoardingSteps'
import advanceCompilerService from '@/services/ide/advanceCompiler.service'
import { useAdvanceCompilerStore } from '@/stores/advanceCompiler.store'
import { useIdeStore } from '@/stores/ide.store'
import Button from '@/components/atoms/button/index.vue'
import { useAdvanceCompilerDatabaseStore } from '@/stores/advanceCompilerDatabase.store'
import advanceCompilerDatabaseService from '@/services/ide/advanceCompilerDatabase.service'

const props = defineProps({
  smallIcon: {
    type: Boolean,
    required: false
  }
})

const advCompilerStore = useAdvanceCompilerStore()

const ideStore = useIdeStore()
const advCompDbStore = useAdvanceCompilerDatabaseStore()

const isPodInitializationCompleted = computed(() => {
  return advCompilerStore.isPodInitializationCompleted
})

const userStartedService = computed(() => {
  return ideStore.userStartedService
})

const serviceRunning = computed(() => {
  return ideStore.advanceServiceRunning
})

const socketInProgress = computed(() => {
  return (
    advCompilerStore.socketInProgress ||
    ((ideStore.userStartedServiceUsingExecute || ideStore.userStoppedServiceUsingStopExecution) &&
      userStartedService.value !== serviceRunning.value)
  )
})

const socketInProgressDb = computed(() => {
  return advCompDbStore.socketInProgressDb
})

const isAdvancedCompilerDatabase = computed(() => {
  return ideStore.isAdvancedCompilerDatabase
})

const isCodeExecutingDb = computed(() => {
  return advCompDbStore.isCodeExecutingDb
})

const socketStatus = computed(() => {
  if (isAdvancedCompilerDatabase.value) {
    return socketInProgressDb.value
  } else {
    return socketInProgress.value
  }
})

/**
 * Asynchronously tries to execute the code by starting pods using the
 * `advanceCompilerService`.
 */
const tryExecute = async () => {
  ideStore.ideFullScreen = false
  if (isAdvancedCompilerDatabase.value) {
    if (window.innerWidth < 640) {
      ideStore.setMobileTab(IDEVIEWTABS_MOBILE.DB_TERMINAL)
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
    advanceCompilerDatabaseService.tryExecuteQuery()
  } else {
    ideStore.setUserStartedService(true)
    ideStore.setUserStartedServiceUsingExecute(true)
    if (window.innerWidth < 640) {
      ideStore.setMobileTab(IDEVIEWTABS_MOBILE.OUTPUTHTML)
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
    advanceCompilerService.startPods()
  }
}
/**
 * Stops the execution of the current process based on the active service.
 *
 * If the `isAdvancedCompilerDatabase` flag is true, it stops the query execution
 * using the `advanceCompilerDatabaseService`. Otherwise, it stops the pods
 * execution using the `advanceCompilerService`.
 */
const stopExecution = () => {
  ideStore.ideFullScreen = false
  if (isAdvancedCompilerDatabase.value) {
    advanceCompilerDatabaseService.stopExecuteQuery()
  } else {
    ideStore.setUserStartedService(false)
    ideStore.setUserStoppedServiceUsingStopExecution(true)
    advanceCompilerService.stopPods()
  }
}
/**
 * Asynchronously installs required libraries by initiating the installation
 * of library pods using the `advanceCompilerService`.
 */
const installLibraries = async () => {
  ideStore.ideFullScreen = false
  if (window.innerWidth < 640) {
    ideStore.setMobileTab(IDEVIEWTABS_MOBILE.OUTPUTHTML)
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }
  advanceCompilerService.installLibraryPods()
}

watch(
  () => ideStore.initServiceRunning,
  async (val) => {
    if (ideStore.isAdvancedCompiler) {
      ideStore.setUserStartedService(val)
    }
  }
)
</script>

<template>
  <div class="flex flex-row items-center justify-end gap-2">
    <div v-if="!props.smallIcon" class="min-w-fit" :id="BoardingElement.INSTALL_PACKAGES">
      <Button
        :disabled="socketStatus || userStartedService || ideStore.advanceServiceRunning"
        variant="filled"
        class="flex h-fit w-fit items-center gap-2 rounded-sm border-0 bg-new-secondary-600 px-3 py-1.5 text-sm font-medium text-white hover:bg-new-secondary-600 sm:hidden lg:block"
        v-if="isPodInitializationCompleted && !isAdvancedCompilerDatabase"
        @click="installLibraries"
      >
        Install Packages
      </Button>
      <Button
        :disabled="socketStatus || userStartedService || ideStore.advanceServiceRunning"
        variant="filled"
        v-if="isPodInitializationCompleted && !isAdvancedCompilerDatabase"
        class="hover:border-bg-new-secondary-600 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-new-secondary-600 bg-new-secondary-600 hover:bg-new-secondary-600 sm:flex lg:hidden"
        @click="installLibraries"
      >
        <FontAwesomeIcon icon="fa-solid fa-download" class="pt-0.3 h-3 w-3" />
      </Button>
    </div>
    <div v-else class="min-w-fit">
      <Button
        :disabled="socketStatus || userStartedService || ideStore.advanceServiceRunning"
        variant="filled"
        v-if="isPodInitializationCompleted && !isAdvancedCompilerDatabase"
        class="hover:border-bg-new-secondary-600 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-new-secondary-600 bg-new-secondary-600 hover:bg-new-secondary-600"
        @click="installLibraries"
      >
        <FontAwesomeIcon icon="fa-solid fa-download" class="pt-0.3 h-3 w-3" />
      </Button>
    </div>
    <div class="min-w-max print:hidden">
      <div v-if="!props.smallIcon">
        <Button
          :disabled="socketStatus"
          variant="filled"
          v-if="
            (!isCodeExecutingDb && isAdvancedCompilerDatabase) ||
            (!serviceRunning && isPodInitializationCompleted && !userStartedService)
          "
          class="flex h-fit w-fit items-center gap-2 rounded-sm border-0 bg-new-primary px-3 py-1.5 text-sm font-medium text-white hover:bg-new-primary-400 sm:hidden lg:block"
          @click="tryExecute"
        >
          Execute
          <FontAwesomeIcon icon="fa-play" class="h-3 w-3" />
        </Button>
        <Button
          :disabled="socketStatus"
          variant="filled"
          data-testid="ExecuteButton"
          v-if="
            (!isCodeExecutingDb && isAdvancedCompilerDatabase) ||
            (!serviceRunning && isPodInitializationCompleted && !userStartedService)
          "
          class="hover:border-bg-btn-primary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-btn-primary bg-btn-primary hover:bg-btn-primary sm:flex lg:hidden"
          @click="tryExecute"
        >
          <FontAwesomeIcon icon="fa-play" class="ml-0.5 h-3 w-3" />
        </Button>
      </div>
      <div v-else>
        <Button
          :disabled="socketStatus"
          variant="filled"
          data-testid="ExecuteButton"
          v-if="
            (!isCodeExecutingDb && isAdvancedCompilerDatabase) ||
            (!serviceRunning && isPodInitializationCompleted && !userStartedService)
          "
          class="hover:border-bg-btn-primary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-btn-primary bg-btn-primary hover:bg-btn-primary"
          @click="tryExecute"
        >
          <FontAwesomeIcon icon="fa-play" class="ml-0.5 h-3 w-3" />
        </Button>
      </div>
      <div v-if="!props.smallIcon">
        <Button
          :disabled="socketStatus"
          variant="filled"
          v-if="
            (isCodeExecutingDb && isAdvancedCompilerDatabase) ||
            serviceRunning ||
            userStartedService
          "
          class="flex h-fit w-fit items-center gap-2 rounded-sm border-0 bg-new-primary px-3 py-1.5 text-sm font-medium text-white hover:bg-new-primary-400 sm:hidden lg:block"
          @click="stopExecution"
        >
          Stop Execution
          <FontAwesomeIcon icon="fa-stop" class="h-3 w-3" />
        </Button>
        <Button
          :disabled="socketStatus"
          variant="filled"
          v-if="
            (isCodeExecutingDb && isAdvancedCompilerDatabase) ||
            serviceRunning ||
            userStartedService
          "
          class="hover:border-bg-btn-primary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-btn-primary bg-btn-primary hover:bg-btn-primary sm:flex lg:hidden"
          @click="stopExecution"
        >
          <FontAwesomeIcon icon="fa-stop" class="h-3 w-3" />
        </Button>
      </div>
      <div v-else>
        <Button
          :disabled="socketStatus"
          variant="filled"
          v-if="
            (isCodeExecutingDb && isAdvancedCompilerDatabase) ||
            serviceRunning ||
            userStartedService
          "
          class="hover:border-bg-btn-primary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-btn-primary bg-btn-primary hover:bg-btn-primary"
          @click="stopExecution"
        >
          <FontAwesomeIcon icon="fa-stop" class="h-3 w-3" />
        </Button>
      </div>
    </div>
  </div>
</template>
