<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useSSGStore } from '@/stores/ssg.store'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { useNavStore } from '@/stores/nav.store'
import { IDEVIEWMODELS } from '@/utils/models'
import { PLAN_ENUM } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import facebookIcon from '@/assets/images/ide/footer/Facebook.svg'
import linkdinIcon from '@/assets/images/ide/footer/Linkedin.svg'
import twitterIcon from '@/assets/images/ide/footer/Twitter.svg'
import gmailIcon from '@/assets/images/ide/footer/gmail.svg'
// import linkIcon from '@/assets/images/ide/footer/link.svg'
import productHunt from '@/assets/images/ide/footer/ProductHunt.svg'
import Instagram from '@/assets/images/ide/footer/Instagram.svg'
import Youtube from '@/assets/images/ide/footer/Youtube.svg'
import SideBarTooltip from '@/components/ide/ide/SettingsSideBarTooltip.vue'
import INeedHelp from '@/components/footer/INeedHelp.vue'

interface IShareItems {
  icon: string
  link: string
  title: string
  color: string
}
const ssgStore = useSSGStore()
const route = useRoute()
const authStore = useAuthStore()
const ideStore = useIdeStore()
const jDroidStore = useJdroidStore()

const navStore = useNavStore()
const subscribeStore = useSubscribeStore()
const organisationStore = useOrganisationStore()

const shareItems: IShareItems[] = [
  {
    icon: facebookIcon,
    link: 'https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fwww.jdoodle.com&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB',
    title: 'Facebook',
    color: 'text-blue-600'
  },
  {
    icon: twitterIcon,
    link: 'https://twitter.com/intent/tweet/?text=JDoodle%20-%20free%20Online%20Compiler,%20Editor%20for%20Java,%20C/C%20,%20etc&url=https%3A%2F%2Fwww.jdoodle.com',
    title: 'Twitter',
    color: 'text-blue-400'
  },
  {
    icon: gmailIcon,
    link: 'mailto:?subject=JDoodle%20-%20free%20Online%20Compiler,%20Editor%20for%20Java,%20C/C%20,%20etc.&body=https%3A%2F%2Fwww.jdoodle.com',
    title: 'Email',
    color: 'text-gray-600'
  },
  {
    icon: linkdinIcon,
    link: 'https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.jdoodle.com&title=JDoodle%20-%20free%20Online%20Compiler,%20Editor%20for%20Java,%20C/C%20,%20etc.&summary=JDoodle%20-%20free%20Online%20Compiler,%20Editor%20for%20Java,%20C/C%20,%20etc.&source=https%3A%2F%2Fwww.jdoodle.com',
    title: 'LinkedIn',
    color: 'text-blue-600'
  },
  {
    icon: Instagram,
    link: 'https://www.instagram.com/jdoodleide/',
    title: 'Instagram',
    color: 'text-blue-600'
  },
  {
    icon: Youtube,
    link: 'https://www.youtube.com/channel/UCMg38oKj3kRmzksoRDEAs9Q',
    title: 'Youtube',
    color: 'text-blue-600'
  }
]

const currentUrl = ref<string>('')
if (!ssgStore.isSSG) currentUrl.value = window?.location.origin + route.fullPath

const isUserloggedIn = computed(() => {
  return authStore.isInitiated && authStore.isUserloggedIn
})
const isFetchingSubscriptionInfo = computed(() => {
  return subscribeStore.isFetchingSubscriptionInfo
})
const accountType = computed(() => {
  return (organisationStore.instituteSubscriptionInfo?.plan as string) || null
})
const isFreePlan = computed(() => {
  return accountType.value === PLAN_ENUM.FREE
})

const isMarketingPage = computed(() => {
  return navStore.isMarketingPage
})

const isCustom = computed(() => {
  return subscribeStore.bundleInitData?.isCustom
})

const showGetMoreCredits = computed(() => {
  return (
    isUserloggedIn.value && !isFetchingSubscriptionInfo.value && isFreePlan.value && !isCustom.value
  )
})

const isPaymentView = computed(() => {
  return subscribeStore.isPaymentView
})

/**
 * Get analytics id
 * @returns - analytics id
 */
const getAnalyticsId = () => {
  if (ideStore.isIdeView) {
    return 'upgrade-to-pro-button-ide'
  } else if (jDroidStore.isJdroidChatView) {
    return 'upgrade-to-pro-button-chat-jdroid'
  } else {
    return 'upgrade-to-pro-button-product-view'
  }
}

watch(
  () => route.fullPath,
  () => {
    currentUrl.value = window.location.origin + route.fullPath
  }
)
</script>

<template>
  <div
    class="stroke-primary-default relative flex h-full w-full flex-col items-center justify-between gap-2 px-6 py-1 sm:flex-row sm:gap-0 sm:border-t sm:px-1 md:px-4"
    :class="[
      { 'section-quinary': ideStore.isIdeView },
      { 'section-primary': !ideStore.isIdeView && !isMarketingPage }
    ]"
  >
    <!-- Social Sharing Card -->
    <div
      class="flex h-full items-center justify-center"
      :class="{ 'hidden sm:block': showGetMoreCredits }"
    >
      <div
        :class="[
          'flex flex-wrap justify-center  gap-4  sm:flex-nowrap',
          { 'sm:gap-1 md:gap-4': showGetMoreCredits }
        ]"
      >
        <div v-for="(item, index) in shareItems" :key="index" class="flex justify-center">
          <a
            :href="item.link"
            target="_blank"
            rel="nofollow"
            :aria-label="item.title"
            class="hs-tooltip flex flex-col items-center justify-start gap-2 rounded-full text-sm [--placement:top]"
          >
            <button type="button" class="hs-tooltip-toggle h-6 w-6">
              <img class="rounded-full" :src="item.icon" />
            </button>
            <SideBarTooltip :content="item.title" />
          </a>
        </div>

        <!-- <div class="flex justify-center">
          <div class="hs-tooltip flex flex-col items-center justify-start gap-2 rounded-full text-sm [--placement:top]">
            <button type="button" class="hs-tooltip-toggle h-6 w-6" @click="copyToClipboard">
              <img class="rounded-full" :src="linkIcon" />
            </button>
            <SideBarTooltip content="Copy" />
          </div>
        </div> -->
      </div>
    </div>
    <div
      v-show="showGetMoreCredits"
      v-if="!isPaymentView"
      class="flex h-full min-w-fit items-center justify-center"
    >
      <div class="flex h-8 flex-wrap items-center justify-center gap-4 sm:flex-nowrap">
        <h6
          class="text-justify text-xs font-medium leading-snug text-slate-500 sm:hidden lg:block dark:text-white"
        >
          Unlock more features
        </h6>
        <div class="flex items-center justify-center">
          <div
            className=" h-6 px-4 py-0.5 bg-emerald-300 rounded-3xl justify-center items-center inline-flex"
          >
            <button
              :id="getAnalyticsId()"
              :data-hs-overlay="`#${IDEVIEWMODELS.JDROIDGETMORECREDITS}`"
              className="text-justify text-black text-xs font-semibold leading-tight"
            >
              Upgrade to Pro
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Review Card -->
    <div class="flex h-full min-w-fit items-center justify-center">
      <div class="flex h-8 items-center justify-center px-0.5 py-1">
        <a
          href="https://www.producthunt.com/products/jdoodle/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-jdoodle"
          rel="nofollow"
          target="_blank"
          class="h-fit"
          ><img
            :src="productHunt"
            alt="JDoodle - JDoodle&#0032;provides&#0032;zero&#0045;setup&#0032;cloud&#0032;IDEs&#0032;for&#0032;76&#0032;languages | Product Hunt"
            style="width: 139px; height: 34px"
            width="139"
            height="34"
            class="inline-block"
        /></a>
      </div>
    </div>

    <!-- maker.io -->
    <INeedHelp />
  </div>
</template>
