import { useIdeStore } from '@/stores/ide.store'

interface PingResponse {
  podReady: boolean
  serviceRunning: boolean
}

let socket: WebSocket | null = null
let initPing = false

/**
 * Creates a WebSocket connection for ping service
 */
const createPingWebSocketService = () => {
  let hostname = ''

  if (process.env.NODE_ENV === 'development') {
    const proxyEnv = import.meta.env.VITE_PROXY_BACKEND_URL as string
    hostname = proxyEnv?.replace(/^https?:\/\/|\/$/g, '')
  } else {
    hostname = window.location.hostname
  }

  socket = new WebSocket(
    `wss://${hostname}/adv-engine/ping?lang=${useIdeStore().ideMeta?.language}`
  )

  socket.onmessage = function (event: MessageEvent<string>) {
    const data: PingResponse = JSON.parse(event.data)
    useIdeStore().setPodReady(data.podReady)
    useIdeStore().setAdvanceServiceRunning(data.serviceRunning)
    if (initPing === false) {
      initPing = true
      useIdeStore().setInitServiceRunning(data.serviceRunning)
    }

    if (useIdeStore().userStartedService && data.serviceRunning) {
      useIdeStore().setUserStartedServiceUsingExecute(false)
    }

    if (!useIdeStore().userStartedService && !data.serviceRunning) {
      useIdeStore().setUserStoppedServiceUsingStopExecution(false)
    }
  }
}

/**
 * Close the WebSocket connection
 */
const disconnectPingWebSocketService = () => {
  if (socket) {
    socket.close()
    socket = null
  }
}

export { createPingWebSocketService, disconnectPingWebSocketService }
