<script setup lang="ts">
import { useAuthStore } from '@/stores/auth.store'
import { computed, ref } from 'vue'
import { useId } from '@/utils/useId'
import ProfilePictureComp from '@/components/header/auth/profile/ProfilePictureComp.vue'
import LogoutComp from '@/components/header/auth/profile/LogoutComp.vue'
import { useNavStore } from '@/stores/nav.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import StartCodingButton from '@/components/ide/ide/ai/launchPage/StartCodingButton.vue'
import UserIcon from '@/assets/images/svg/profile-menu/user.svg?component'
import DashboardIcon from '@/assets/images/svg/profile-menu/dashboard.svg?component'
import CreditCardIcon from '@/assets/images/svg/profile-menu/credit-card.svg?component'

const authStore = useAuthStore()
const organisationStore = useOrganisationStore()
const navStore = useNavStore()

const id = useId()

const dropdown = ref<HTMLDivElement | null>(null)
const dropdownItem = ref<HTMLDivElement | null>(null)

const isCollapsedHeader = computed(() => {
  return navStore.isCollapsedHeader
})
const isProductPage = computed(() => {
  return !navStore.isMarketingPage
})

const showPersonalProfilePic = computed(() => {
  return Boolean(organisationStore.personalAccountId)
})

const currentAccountName = computed(() => {
  return authStore.firstName
})

const userEmail = computed(() => {
  return authStore.userEmail
})

// Roles
const showPlansPayment = computed(() => {
  return (
    organisationStore.isOrganisationOwner ||
    organisationStore.isIndividualAccount ||
    organisationStore.isOrganisationAdmin
  )
})

const showAssessmentsManagement = computed(() => {
  return (
    organisationStore.isOrganisationOwner ||
    organisationStore.isOrganisationAdmin ||
    organisationStore.isTeacher ||
    organisationStore.isInstituteAdmin
  )
})

const showStudentAssessment = computed(() => {
  return organisationStore.isStudent
})

const showApi = computed(() => {
  return (
    organisationStore.isOrganisationOwner ||
    organisationStore.isOrganisationAdmin ||
    organisationStore.isApiAdmin ||
    organisationStore.isApiViewer ||
    organisationStore.isIndividualAccount
  )
})

const showEmbed = computed(() => {
  return (
    organisationStore.isOrganisationOwner ||
    organisationStore.isOrganisationAdmin ||
    organisationStore.isEmbedAdmin ||
    organisationStore.isEmbedViewer ||
    organisationStore.isIndividualAccount
  )
})

const showTeams = computed(() => {
  return (
    organisationStore.isOrganisationOwner ||
    organisationStore.isOrganisationAdmin ||
    organisationStore.isTeacher
  )
})
</script>

<template>
  <div
    ref="dropdown"
    class="relative z-50 flex h-full w-auto flex-col"
    :class="[
      {
        'hs-dropdown sm:h-full sm:flex-row sm:[--auto-close:inside] sm:[--offset:25] sm:[--placement:bottom-right] sm:[--strategy:absolute]  lg:inline-flex':
          !isCollapsedHeader
      }
    ]"
  >
    <!-- START Profile Button -->
    <button
      :id="isCollapsedHeader ? 'hs-collapse-mobile-profile' + `-${id}` : 'hs-dropdown-profile'"
      type="button"
      class="flex items-center gap-2 px-1 py-2 sm:inline-flex"
      :class="[
        {
          'hs-dropdown-toggle': !isCollapsedHeader
        },
        {
          'hs-collapse-toggle': isCollapsedHeader
        },
        {
          'lg:p-0': isProductPage
        }
      ]"
      :data-hs-collapse="`#hs-collapse-mobile-profile-${id}-item`"
    >
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center gap-2">
          <ProfilePictureComp
            :showPersonalProfilePic="showPersonalProfilePic"
            :isUserProfileImage="true"
            :currentName="currentAccountName"
          />

          <span v-if="isCollapsedHeader" class="text-md flex font-medium">{{
            currentAccountName
          }}</span>
        </div>
        <FontAwesomeIcon
          icon="fa-chevron-down"
          class="mr-3 h-3 w-3 hs-collapse-open:rotate-180"
          :class="[
            {
              'sm:hidden': isProductPage || !isCollapsedHeader
            }
          ]"
        />
      </div>
    </button>
    <!-- END Profile Button -->

    <!-- START Profile dropdown -->
    <div
      ref="dropdownItem"
      class="lg:stroke-primary-default background-primary w-full min-w-[12rem] rounded-lg p-1 sm:min-w-[15rem] lg:border"
      :class="[
        {
          'hs-collapse hidden overflow-hidden': isCollapsedHeader,
          'hs-dropdown-menu absolute -top-4 hidden overflow-hidden opacity-0 shadow-md transition-[opacity,margin,height] hs-dropdown-open:opacity-100 dark:shadow-new-neutral-900':
            !isCollapsedHeader,
          '-top-[1.25rem]': !isCollapsedHeader && isProductPage
        }
      ]"
      :aria-labelledby="
        isCollapsedHeader ? 'hs-collapse-mobile-profile' + `-${id}` : 'hs-dropdown-profile'
      "
      :id="`hs-collapse-mobile-profile-${id}-item`"
    >
      <!-- START User Basic Info -->
      <div class="surface-primary-header mb-0.5 flex items-center gap-2 rounded p-2">
        <ProfilePictureComp
          :show-personal-profile-pic="showPersonalProfilePic"
          :isUserProfileImage="true"
          :currentName="currentAccountName"
          :widthAndHeightClass="'h-12 w-12'"
        />
        <div class="overflow-hidden truncate">
          <h3 class="text-sm font-semibold">{{ currentAccountName }}</h3>
          <p class="text-low text-xs">{{ userEmail }}</p>
        </div>
      </div>
      <!-- END User Basic Info -->

      <!-- START Menu buttons and links -->
      <div class="flex flex-col">
        <!-- Only when individual profile -->
        <RouterLink
          class="text-button-secondary hover:surface-primary-header hover:text-high flex items-center gap-5 rounded px-2 py-1.5 text-sm font-medium lg:gap-3"
          to="/profile"
        >
          <UserIcon /> Profile
        </RouterLink>
        <hr class="stroke-primary-default my-0.5" />
        <!-- Start Dashboard -->
        <div>
          <RouterLink
            class="text-button-secondary hover:surface-primary-header hover:text-high flex items-center gap-5 rounded px-2 py-1.5 text-sm font-medium lg:gap-3"
            to="/dashboard"
          >
            <DashboardIcon /> Dashboard
          </RouterLink>

          <div>
            <StartCodingButton :isProfileMenu="true" />
            <RouterLink
              class="text-button-secondary hover:surface-primary-header hover:text-high block rounded py-1.5 pl-[3.25rem] text-sm font-medium md:pl-9"
              to="/chat-jdroid"
            >
              Chat JDroid
            </RouterLink>
            <RouterLink
              v-if="showAssessmentsManagement || showStudentAssessment"
              class="text-button-secondary hover:surface-primary-header hover:text-high block rounded py-1.5 pl-[3.25rem] text-sm font-medium md:pl-9"
              :to="`/${showStudentAssessment ? 'student-assessments' : 'assessments'}`"
            >
              Assessments
            </RouterLink>
            <RouterLink
              v-if="showEmbed"
              class="text-button-secondary hover:surface-primary-header hover:text-high block rounded py-1.5 pl-[3.25rem] text-sm font-medium md:pl-9"
              to="/embed-manager"
            >
              Embed
            </RouterLink>
            <RouterLink
              v-if="showApi"
              class="text-button-secondary hover:surface-primary-header hover:text-high block rounded py-1.5 pl-[3.25rem] text-sm font-medium md:pl-9"
              to="/subscribe-api"
            >
              API
            </RouterLink>
            <RouterLink
              v-if="showTeams || showPlansPayment"
              class="text-button-secondary hover:surface-primary-header hover:text-high block rounded py-1.5 pl-[3.25rem] text-sm font-medium md:pl-9"
              :to="organisationStore.isTeacher ? `/teams/student-groups` : `/teams/users`"
            >
              Manage Teams
            </RouterLink>
            <hr class="stroke-primary-default my-0.5" />
          </div>
        </div>

        <RouterLink
          v-if="showPlansPayment"
          class="text-button-secondary hover:surface-primary-header hover:text-high flex items-center gap-5 rounded px-2 py-1.5 text-sm font-medium lg:gap-3"
          to="/plans-payments"
        >
          <CreditCardIcon />
          Plans & Payments
        </RouterLink>

        <LogoutComp v-if="!isCollapsedHeader" />
      </div>
      <!-- END Menu buttons and links -->
    </div>
    <!-- END Profile dropdown -->
  </div>
</template>
