<script setup lang="ts">
import { computed } from 'vue'
import { IDEVIEWTABS_MOBILE } from '@/utils/tabs'
import executeService from '@/services/ide/execute.service'
import htmlExecuteService from '@/services/ide/languages/html/execute.service'
import { useIdeStore } from '@/stores/ide.store'
const props = defineProps({
  smallIcon: {
    type: Boolean,
    required: false
  }
})

const ideStore = useIdeStore()

const isDefaultIde = computed(() => {
  return ideStore.isDefaultIde
})
const isHtml = computed(() => {
  return ideStore.isHtml
})
const isAdvanced = computed(() => {
  return ideStore.isAdvanced
})
const isCodeExecuting = computed(() => {
  return ideStore.isCodeExecuting
})
/**
 * Tries to execute the code
 */
const tryExecute = async () => {
  ideStore.ideFullScreen = false
  ideStore.setIdeFullScreenMaximizedState(false)
  if (isHtml.value) {
    if (!isAdvanced.value) {
      ideStore.setCurrentIframeSourceBrowser('/index.html')
    } else {
      ideStore.setCurrentIframeSourceBrowser(ideStore?.project?.home)
    }

    if (window.innerWidth < 640) {
      ideStore.setMobileTab(IDEVIEWTABS_MOBILE.OUTPUTHTML)
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }

    htmlExecuteService.tryExecute()
  } else {
    if (window.innerWidth < 640) {
      ideStore.setMobileTab(IDEVIEWTABS_MOBILE.OUTPUT)
    }

    executeService.tryExecute()
  }
}
/**
 * Stops the execution
 */
const stopExecution = () => {
  if (isHtml.value) {
    htmlExecuteService.stopExecution()
  } else {
    executeService.stopExecution()
  }
}
</script>

<template>
  <div class="min-w-max print:hidden">
    <div>
      <div v-if="!props.smallIcon">
        <button
          id="execute-ide"
          :disabled="isDefaultIde"
          v-if="!isCodeExecuting"
          class="flex h-fit w-fit items-center gap-2 rounded-sm bg-new-primary px-3 py-1.5 text-sm font-medium text-white hover:bg-new-primary-400"
          @click="tryExecute"
        >
          Execute
          <FontAwesomeIcon icon="fa-play" class="h-3 w-3" />
        </button>
      </div>
      <div v-else>
        <button
          id="execute-ide"
          v-if="!isCodeExecuting"
          data-testid="ExecuteButton"
          class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-new-primary pl-[2px] text-white"
          @click="tryExecute"
        >
          <FontAwesomeIcon icon="fa-play" class="h-3 w-3" />
        </button>
      </div>
    </div>
    <div>
      <div v-if="!props.smallIcon">
        <button
          v-if="isCodeExecuting"
          class="flex h-fit w-fit items-center gap-2 rounded-sm bg-new-primary px-3 py-1.5 text-sm font-medium text-white hover:bg-new-primary-400"
          @click="stopExecution"
        >
          Stop Execution
          <FontAwesomeIcon spin icon="fa-spinner" class="h-3 w-3" />
        </button>
      </div>
      <div v-else>
        <button
          v-if="isCodeExecuting"
          class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-new-primary text-white"
          @click="stopExecution"
        >
          <FontAwesomeIcon icon="fa-stop" class="h-3 w-3" />
        </button>
      </div>
    </div>
  </div>
</template>
