<script setup lang="ts">
import { computed } from 'vue'
import { useIdeStore } from '@/stores/ide.store'

const props = defineProps({
  mobileIdeVersion: {
    type: Boolean,
    required: false,
    default: false
  }
})

const ideStore = useIdeStore()

const isIdeView = computed(() => {
  return ideStore.isIdeView
})
</script>
<template>
  <div
    :class="[
      'hs-dropdown-menu section-primary left-0 top-full z-10 hidden w-full border-b border-t  opacity-0 transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100 sm:duration-[150ms] lg:border-b-0 lg:shadow-lg dark:border-text-secondary dark:border-t-text-secondary',
      { 'section-quinary': isIdeView },
      { 'max-w-full': ideStore.currentMobileTab != null },
      { 'w-full min-w-[15rem] border-text-tertiary': ideStore.currentMobileTab == null }
    ]"
  >
    <div class="view-container w-full justify-around pb-4 sm:pt-5 lg:flex lg:flex-col lg:pt-10">
      <slot></slot>

      <div
        v-if="!props.mobileIdeVersion"
        class="p-small lg:p-normal leading-12 mt-8 hidden border-t border-text-tertiary py-8 font-light lg:block dark:border-gray-700 dark:border-text-secondary"
      >
        <span class="italic"
          >Are you an education institution or business looking for a custom solution? Easy!
        </span>
        <RouterLink
          to="/contact-us"
          class="color-secondary-600 mx-1 cursor-pointer text-left underline"
        >
          Contact Us</RouterLink
        >
        <span class="italic"> and we will build one for you.</span>
      </div>
    </div>
  </div>
</template>
