<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useIdeStore } from '@/stores/ide.store'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'

const ideStore = useIdeStore()
const route = useRoute()

const languages: ILanguage[] = [...languagesItems].filter((langauge: ILanguage) => {
  return !langauge.isAdvanced
})

const isDefaultIde = computed(() => {
  return ideStore.isDefaultIde
})
const routeUrl = computed(() => {
  return route.path
})
const type = computed(() => {
  return 'IDE'
})
const langDisplayName = computed(() => {
  return ideStore.ideMeta?.langDisplayName
})
const isCompileLang = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})

const isShareNotFound = computed(() => {
  return ideStore.shareNotFound
})

const item = computed(() => {
  return languages.find((lang) => lang.language === ideStore.ideMeta?.language)
})

const isDark = computed(() => {
  if (ideStore.isIdeView) {
    if (ideStore?.colorMode == 'dark') return true
    return false
  }
  return false
})
</script>

<template>
  <div v-if="!isShareNotFound" class="hidden w-full sm:inline-block">
    <div class="text-center">
      <div class="flex flex-col items-center md:leading-tight">
        <div
          v-if="!isDefaultIde"
          class="flex w-full min-w-fit items-center justify-center gap-2 overflow-hidden"
        >
          <img
            v-if="item"
            :src="isDark ? (item.darkIcon ? item.darkIcon : item.icon) : item.icon"
            :alt="item.displayName"
            class="h-5 w-5"
          />
          <!-- Light -->
          <h1 class="text-mid truncate text-base">
            <span>Online</span> {{ langDisplayName }} <span v-if="isCompileLang">Compiler </span
            ><span> {{ type }}</span>
          </h1>
        </div>
        <h1 v-else class="text-mid truncate text-base">
          <span>Online Compiler IDE</span>
        </h1>
      </div>
      <p v-if="!isDefaultIde" class="p-normal link-secondary hidden text-center print:block">
        https://www.jdoodle.com{{ routeUrl }}
      </p>
    </div>
  </div>
</template>
