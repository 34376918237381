export interface IExecuteRequestAdvanceCompiler {
  lang: string | null
  type: string | null
  projectId: number | null
  projectKey: string | boolean | number
  shareId: number | null
  isInstant: boolean
}

export interface IExecuteQuery {
  script: string
  language: string
  database: string | null
}

export interface IExecuteQuerySelectTable {
  language: string
  database: string | null
  table: string
}

export enum IDATABASETYPE {
  DATABASE = 'database',
  SCHEMA = 'schema',
  TABLE = 'tables'
}

export interface IDATABASECHILDREN {
  name: string
  type: IDATABASETYPE
  parent?: { database: string; schema?: string | null }
  children?: IDATABASECHILDREN[]
  isTableOpened?: boolean
}

export interface IDATABASESTRUCT {
  name: string
  type: IDATABASETYPE
  children?: IDATABASECHILDREN[]
  isDatabaseOpened?: boolean
  isSchemasOpened?: boolean
}

export interface CodeQuery {
  id: number
  version: number
  fileName: string
  databaseName: string
  script: string
  language: string
  lastUpdated: string
  userId: number
  dateCreated: string
  lastAccess: string
}

export interface CodeQueryRequest {
  language: string
  script: string
  database?: string | null
  fileName: string
}

export interface CodeQueryDto {
  id: number
  lastAccess: string
  lastUpdate: string
  database?: string | null
  fileName: string
  language: string
  isRename?: boolean
  renamedFileName?: string
}

import {
  CURRENT_RUNNING_WS_SERVICE,
  INIT_NEXT_ACTION
} from '@/components/organisation/enum/advanceCompiler.enums'
import advanceDbWsService from '@/services/ide/advanceDbWs.service'
import editorService from '@/services/ide/editor.service'
import tabsService from '@/services/ide/tabs.service'
import recaptchaService from '@/services/recaptcha.service'
import { useAdvanceCompilerDatabaseStore } from '@/stores/advanceCompilerDatabase.store'
import { useApiStore } from '@/stores/api.store'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { DEFAULTDBTAB, IDECONSTANT, SERVER_ERROR } from '@/utils/ide'
import { TAB_ICONS_NAMES } from '@/utils/tabs'
import axios from 'axios'
import stripAnsi from 'strip-ansi'

let controller: AbortController | null = null
const EMPTY_QUERY_MESSAGE = 'No results found for your query.'

/**
 * Removes ANSI escape codes from the provided text string.
 * @param text - The input string potentially containing ANSI escape codes.
 * @returns The cleaned string with ANSI codes removed.
 */
const cleanAnsiCodes = (text: string): string => {
  // eslint-disable-next-line no-control-regex
  return text.replace(/\u001b\[[0-9;]*m/g, '')
}
/**
 * Sets and logs messages for the advanced compiler database, and updates the editor session.
 *
 * This function cleans the provided message of any ANSI codes and sets various
 * state flags and log messages in the `useAdvanceCompilerDatabaseStore`. It also
 * updates the editor session for the advanced compiler terminal with the cleaned message.
 * @param  message - The raw message string to be processed and logged.
 */
const setMessages = (message: string) => {
  const script = cleanAnsiCodes(message)
  useAdvanceCompilerDatabaseStore().setHideOutputEditor(true)
  useAdvanceCompilerDatabaseStore().setStartLoggingMessages(true)
  useAdvanceCompilerDatabaseStore().setDatabaseLogMessages(script)
}
/**
 * Initializes the pods for the advance database compiler by gathering necessary data
 * from the IDE and database compiler stores, constructing a request, and sending it
 * via WebSocket.
 */
const initPodsAdvanceCompilerDatabase = () => {
  const language = useIdeStore().routeMeta?.language
  const sessionId = useAdvanceCompilerDatabaseStore().sessionId
  const kurukkuKuriId = useAdvanceCompilerDatabaseStore().kurukkuKuriId
  const requestData: any = {
    destination: `/box/${CURRENT_RUNNING_WS_SERVICE.INIT}`,
    message: `{"language": "${language}"}`,
    message_type: 'execute',
    sessionId: sessionId,
    signature: kurukkuKuriId
  }
  useAdvanceCompilerDatabaseStore().setLoadingPods(true)
  useAdvanceCompilerDatabaseStore().setDatabaseLogMessages([])
  useAdvanceCompilerDatabaseStore().setIsPodInitializationCompleted(false)
  advanceDbWsService.sendWs(requestData)
}

/**
 * Asynchronously waits for 5 second and then initializes the advance compiler database.
 */
const callInitAgain = async () => {
  await new Promise((resolve) => setTimeout(resolve, 5000))
  initAdvanceCompilerDatabase()
}

/**
 * Asynchronously checks the status of existing pods by sending a POST request
 * to the `/adv-engine/box/init` endpoint with the provided initialization request data.
 * @param  request - The initialization request object containing the necessary data.
 * @returns A promise that resolves with the response data from the API.
 */
const checkExistingPods = async (request: object) => {
  const res = await axios.post('/adv-engine/box/init', request)
  return res.data
}

/**
 * Initializes the advance compiler for the current project by checking existing pods
 * and determining the next action based on the response.
 */
const initAdvanceCompilerDatabase = async () => {
  const projectLanguage = useIdeStore().isLanguage as string

  if (projectLanguage) {
    const request = {
      language: projectLanguage
    }

    const response = await checkExistingPods(request)

    if (response) {
      useAdvanceCompilerDatabaseStore().setSessionId(response?.identifier)
      useAdvanceCompilerDatabaseStore().setkurukkuKuriId(response?.signature)
      useAdvanceCompilerDatabaseStore().setInitNextAction(response?.nextAction)

      switch (response.nextAction) {
        case INIT_NEXT_ACTION.INIT_POD:
          initPodsAdvanceCompilerDatabase()
          break
        case INIT_NEXT_ACTION.READY:
          useAdvanceCompilerDatabaseStore().setIsPodInitializationCompleted(true)
          useAdvanceCompilerDatabaseStore().setLoadingPods(false)
          useAdvanceCompilerDatabaseStore().setSocketCompleted(true)
          break
        case INIT_NEXT_ACTION.WAIT: {
          callInitAgain()
          break
        }

        default:
          break
      }
    }
  }
}

/**
 * Cleans up the advance compiler database store by closing the WebSocket connection
 * and resetting the store state.
 */
const cleanAdvanceCompilerDatabaseStore = async () => {
  advanceDbWsService.closeWebSocketConnection()
  useAdvanceCompilerDatabaseStore().$reset()
}

/**
 * Asynchronously checks the status of existing database by sending a POST request
 * to the `/adv-engine/box/detail` endpoint with the provided initialization request data.
 * @param  request - The initialization request object containing the necessary data.
 * @returns A promise that resolves with the response data from the API.
 */
const checkDatabaseInfo = async (request: object) => {
  const res = await axios.post('/adv-engine/box/detail', request)
  return res.data
}

/**
 * @description To change the array structure for toggling the array stricture
 * @param result - take the array
 * @returns - formatted array with the boolean toggle added
 */
const formatDatabaseArray = (result: any[]): IDATABASESTRUCT[] => {
  const previousStructure: IDATABASESTRUCT[] = useAdvanceCompilerDatabaseStore().databaseListNames

  return Array.isArray(result)
    ? result.map((db: Record<string, any>): IDATABASESTRUCT => {
        const dbName = db.database
        const previousDbState = previousStructure.find(
          (prevDb: IDATABASESTRUCT) => prevDb.name === dbName
        )

        const dbObj: IDATABASESTRUCT = {
          name: dbName,
          type: IDATABASETYPE.DATABASE,
          children: [] as IDATABASECHILDREN[],
          isDatabaseOpened: previousDbState ? previousDbState.isDatabaseOpened : false,
          isSchemasOpened: previousDbState ? previousDbState.isSchemasOpened : false
        }

        Object.keys(db).forEach((key) => {
          if (key !== IDATABASETYPE.DATABASE && Array.isArray(db[key])) {
            if (key === IDATABASETYPE.TABLE) {
              dbObj.children = db[key].map((table: string): IDATABASECHILDREN => {
                const previousTableState = previousDbState?.children?.find(
                  (child: IDATABASECHILDREN) =>
                    child.name === table && child.type === IDATABASETYPE.TABLE
                )

                return {
                  name: table,
                  type: IDATABASETYPE.TABLE,
                  parent: { database: dbName, schema: null },
                  isTableOpened: previousTableState ? previousTableState.isTableOpened : false
                }
              })
            } else {
              dbObj.children = db[key].map((item: Record<string, any>): IDATABASECHILDREN => {
                const itemKey = Object.keys(item).find(
                  (k) => k !== IDATABASETYPE.TABLE && k !== 'name'
                )

                if (itemKey) {
                  const previousItemState = previousDbState?.children?.find(
                    (child: IDATABASECHILDREN) =>
                      child.name === item[itemKey] && child.type === itemKey
                  )

                  return {
                    name: item[itemKey],
                    type: itemKey as IDATABASETYPE,
                    children: (item.tables || []).map((table: string): IDATABASECHILDREN => {
                      const previousNestedTableState = previousItemState?.children?.find(
                        (child: IDATABASECHILDREN) =>
                          child.name === table && child.type === IDATABASETYPE.TABLE
                      )

                      return {
                        name: table,
                        type: IDATABASETYPE.TABLE,
                        parent: { database: dbName, schema: item[itemKey] },
                        isTableOpened: previousNestedTableState
                          ? previousNestedTableState.isTableOpened
                          : false
                      }
                    }),
                    isTableOpened: previousItemState ? previousItemState.isTableOpened : false
                  }
                } else {
                  return {
                    name: item.name,
                    type: IDATABASETYPE.TABLE,
                    parent: { database: dbName, schema: null }
                  }
                }
              })
            }
          }
        })

        return dbObj
      })
    : []
}

/**
 * Updates the database file tree with the database names from the provided result.
 *
 * This function checks if the result is an array. If it is, it maps over the array
 * to create a list of database objects, each containing `name`, `type`, and `parent`
 * properties. These objects represent individual databases in a hierarchical file tree format.
 * If the database list is not empty, it updates the `databaseFileTree` in the
 * `useAdvanceCompilerDatabaseStore`.
 * @param result - The result containing the database information, expected to be an array.
 */
const updateDatabaseNames = (result: any) => {
  const databases = formatDatabaseArray(result)
  if (databases.length > 0) {
    const selectedDatabaseName = useAdvanceCompilerDatabaseStore().selectedDataBaseName
    let matchFound = false
    databases.map((item) => {
      if (item.name == selectedDatabaseName) {
        matchFound = true
      }
    })
    if (!matchFound) {
      useAdvanceCompilerDatabaseStore().setSelectedDataBaseName(null)
    }

    useAdvanceCompilerDatabaseStore().setDatabaseListNames(databases)
    useAdvanceCompilerDatabaseStore().setDatabaseListFetchCompleted(true)
  } else if (databases.length == 0) {
    useAdvanceCompilerDatabaseStore().setDatabaseListNames([])
    useAdvanceCompilerDatabaseStore().setDatabaseListFetchCompleted(true)
  } else {
    useAdvanceCompilerDatabaseStore().setDatabaseListFetchCompleted(false)
  }
}

/**
 * Handles errors.
 * @param error - The error object caught during API calls.
 */
const handleError = (error: any) => {
  if (error?.response?.status === 403) {
    useAuthStore().clearRobotCheck()
  }
}

/**
 * Asynchronously fetches the list of databases from the server and updates
 * the internal state with the received data.
 * @param  request - The request object containing necessary data
 * to fetch the list of databases. This object should match the expected
 * structure required by the API endpoint.
 * @returns  A promise that resolves with the response data
 * from the API, which contains the list of databases.
 * @throws  Throws an error if the request fails or if the
 * response data cannot be processed.
 */
const listAllDatabases = async (request: object): Promise<any> => {
  useAdvanceCompilerDatabaseStore().setLoadingDatabaseList(true)
  useAdvanceCompilerDatabaseStore().setDatabaseListFetchCompleted(false)
  try {
    const res = await axios.post('/adv-engine/database/list', request)
    updateDatabaseNames(res.data)

    return res.data
  } catch (error) {
    handleError(error)
  } finally {
    useAdvanceCompilerDatabaseStore().setLoadingDatabaseList(false)
  }
}
/**
 * Checks if the provided data is a valid non-empty JSON array of objects.
 *
 * This function verifies that the input is an array, is not empty, and every item
 * in the array is a non-null object with at least one key. If all these conditions
 * are met, it returns `true`; otherwise, it returns `false`.
 * @param  data - The data to validate, expected to be an array of objects.
 * @returns  Returns `true` if the data is a valid non-empty array of objects, otherwise `false`.
 */
const isValidJsonArray = (data: any) => {
  if (!Array.isArray(data) || data.length == 0) {
    return false
  }

  return data.every(
    (item) => typeof item === 'object' && item !== null && Object.keys(item).length > 0
  )
}

/**
 * Formats the MongoDB response by removing connection details and trimming the result.
 *
 * This function specifically removes any part of the string that matches the regex
 * `/.*admin\?directConnection.*\u0007/` which typically includes MongoDB connection details
 * and control characters, and returns the cleaned-up response.
 * @param  data - The raw MongoDB response string to be formatted.
 * @returns  The formatted MongoDB response string, with the connection details removed and trimmed.
 */
const formatMongoResponse = (data: string) => {
  data.replace(/[\r\n]+/g, '').trim()
  // eslint-disable-next-line no-control-regex
  const formatedData = data.replace(/^.*authSource=admin\u0007/, '')
  return formatedData
}
/**
 *
 * @param value take value as params
 * @returns return boolean by checking primitive type
 */
const isPrimitive = (value: any) =>
  value === null || ['string', 'number', 'boolean'].includes(typeof value)
/**
 *
 * @param table take the table data
 * @returns returns the boolean by checking the valid table format
 */
const isValidTableFormat = (table: any) => {
  if (typeof table !== 'object' || !Object.keys(table).length) return false

  const keys = Object.keys(table)
  const columnLength = table[keys[0]].length

  for (const key of keys) {
    const column = table[key]

    if (!Array.isArray(column) || column.length !== columnLength || !column.every(isPrimitive)) {
      return false
    }
  }

  return true
}

/**
 * Determines the response type and updates the state accordingly.
 *
 * This function checks if the provided data is a valid JSON array of objects using
 * `isValidJsonArray`. If the data is valid, it hides the output editor and logs
 * the query result in the `useAdvanceCompilerDatabaseStore`. If the data is not valid,
 * it shows the output editor and updates the editor session with the raw data.
 * @param data - The response data to check, which could be a JSON array or other type.
 */
const checkResponseType = (data: any) => {
  if (isValidJsonArray(data)) {
    useAdvanceCompilerDatabaseStore().setHideOutputEditor(true)
    useAdvanceCompilerDatabaseStore().setExecuteQueryResponse(null)
    useAdvanceCompilerDatabaseStore().setLogExecuteQueryResult(data)
  } else if (typeof data == 'object') {
    if (isValidTableFormat(data)) {
      useAdvanceCompilerDatabaseStore().setHideOutputEditor(true)
      useAdvanceCompilerDatabaseStore().setExecuteQueryResponse(null)
      useAdvanceCompilerDatabaseStore().setLogExecuteQueryResult(data)
    } else {
      useAdvanceCompilerDatabaseStore().setLogExecuteQueryResult(null)
      useAdvanceCompilerDatabaseStore().setHideOutputEditor(false)
      useAdvanceCompilerDatabaseStore().setExecuteQueryResponse(JSON.stringify(data, null, 2))
      editorService.setEditorSession(IDECONSTANT.ADVCOMPILERTERMINAL, JSON.stringify(data, null, 2))
    }
  } else {
    useAdvanceCompilerDatabaseStore().setLogExecuteQueryResult(null)
    useAdvanceCompilerDatabaseStore().setHideOutputEditor(false)
    const language = useIdeStore().isLanguage

    if (language == 'mongo') {
      const response = formatMongoResponse(data)

      if (!response) {
        useAdvanceCompilerDatabaseStore().setExecuteQueryResponse(EMPTY_QUERY_MESSAGE)
        editorService.setEditorSession(IDECONSTANT.ADVCOMPILERTERMINAL, EMPTY_QUERY_MESSAGE)
      } else {
        const finalText = stripAnsi(response)
        useAdvanceCompilerDatabaseStore().setExecuteQueryResponse(finalText)
        editorService.setEditorSession(IDECONSTANT.ADVCOMPILERTERMINAL, finalText)
      }
    } else if (!data.length) {
      useAdvanceCompilerDatabaseStore().setExecuteQueryResponse(EMPTY_QUERY_MESSAGE)
      editorService.setEditorSession(IDECONSTANT.ADVCOMPILERTERMINAL, EMPTY_QUERY_MESSAGE)
    } else {
      useAdvanceCompilerDatabaseStore().setExecuteQueryResponse(data)
      editorService.setEditorSession(IDECONSTANT.ADVCOMPILERTERMINAL, data)
    }
  }
}

/**
 * @description - Initializes the abort controller if it's not already initialized,
 * otherwise, it aborts the current controller and creates a new one.
 */
const initializeAbortController = () => {
  if (controller) {
    controller.abort()
    controller = null
  }
  controller = new AbortController()
}

/**
 * @param databaseName - passing database name
 * @param table - passing table name
 */
const executeSelectedTable = async (databaseName: string, table: string) => {
  initializeAbortController()
  tabsService.updateTabVisiblity(TAB_ICONS_NAMES.DB_TERMINAL)
  editorService.setEditorSession(IDECONSTANT.ADVCOMPILERTERMINAL, '')
  useAdvanceCompilerDatabaseStore().setServerErrorMessages(null)
  useAdvanceCompilerDatabaseStore().setLogExecuteQueryResult(null)

  let requestData: IExecuteQuerySelectTable | null = null
  const language = useIdeStore().isLanguage

  requestData = {
    database: databaseName,
    language: language,
    table: table
  }

  recaptchaService
    .callViaCaptcha()
    .then(async () => {
      await axios
        .post('/adv-engine/database/selectTable', JSON.stringify(requestData), {
          signal: controller?.signal,
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response: { data: any }) => {
          checkResponseType(response?.data)
        })
        .catch((error: { response: { status: number } }) => {
          if (error?.response?.status === 403) {
            useAuthStore().clearRobotCheck()
          }
          if (error?.response?.status === 500) {
            useAdvanceCompilerDatabaseStore().setServerErrorMessages(SERVER_ERROR)
          }
          useAdvanceCompilerDatabaseStore().setServerErrorMessages(SERVER_ERROR)
        })
        .finally(() => {
          useAdvanceCompilerDatabaseStore().setIsCodeExecutingDb(false)
        })
    })
    .catch(() => {
      useAdvanceCompilerDatabaseStore().setIsCodeExecutingDb(false)
    })
}

/**
 * Attempts to execute a query on the advanced compiler database.
 *
 * This function checks if a query is already executing. If not, it initializes
 * an `AbortController` for the request and sets the state indicating that a query
 * execution is in progress. It then resets the output terminal session in the editor.
 */
const tryExecuteQuery = () => {
  if (useAdvanceCompilerDatabaseStore().isPodInitializationHasErrors) {
    initPodsAdvanceCompilerDatabase()
  } else {
    if (useAdvanceCompilerDatabaseStore().isCodeExecutingDb) return
    initializeAbortController()
    tabsService.updateTabVisiblity(TAB_ICONS_NAMES.DB_TERMINAL)
    useAdvanceCompilerDatabaseStore().setIsCodeExecutingDb(true)
    editorService.setEditorSession(IDECONSTANT.ADVCOMPILERTERMINAL, '')
    useAdvanceCompilerDatabaseStore().setServerErrorMessages(null)
    useAdvanceCompilerDatabaseStore().setLogExecuteQueryResult(null)

    recaptchaService
      .callViaCaptcha()
      .then(() => {
        let requestData: IExecuteQuery | null = null
        const script = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
        const language = useIdeStore().isLanguage
        const databaseName = useAdvanceCompilerDatabaseStore().selectedDataBaseName

        requestData = {
          script: script,
          language: language,
          database: databaseName
        }

        axios
          .post('/adv-engine/database/execute-query', JSON.stringify(requestData), {
            signal: controller?.signal,
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(async (response: { data: any }) => {
            checkResponseType(response?.data)
            if (!useIdeStore().activeItem?.id) {
              useAdvanceCompilerDatabaseStore().setIsFetchingQueryLogs(true)
            }
            await listAllDatabases({ language })

            if (!useIdeStore().activeItem?.id) {
              const fileName = `${DEFAULTDBTAB}_${
                useAdvanceCompilerDatabaseStore().queryLogs?.length + 1
              }`

              addQueryLogs({
                language: language,
                script: script,
                database: databaseName,
                fileName
              })
                .then((response) => {
                  const newQueryId = response.data?.id
                  useIdeStore().setUniqueActiveItemAdvIde({ name: fileName, id: newQueryId })
                  useIdeStore().setActiveFileDataBase({ id: newQueryId })

                  queryLogsList()
                    .then((response) => {
                      useAdvanceCompilerDatabaseStore().setQueryLogs(response.data)
                      useAdvanceCompilerDatabaseStore().setIsFetchingQueryLogs(false)
                    })
                    .catch(() => {
                      useAdvanceCompilerDatabaseStore().setIsFetchingQueryLogs(false)
                    })
                })
                .catch(() => {
                  useApiStore().setGlobalMsg('Failed to add Query!')
                })
            } else {
              updateQueryLogs()
            }
          })
          .catch((error: { response: { status: number } }) => {
            if (error?.response?.status === 403) {
              useAuthStore().clearRobotCheck()
            }
            if (error?.response?.status === 500) {
              useAdvanceCompilerDatabaseStore().setServerErrorMessages(SERVER_ERROR)
            }
            useAdvanceCompilerDatabaseStore().setServerErrorMessages(SERVER_ERROR)
          })
          .finally(() => {
            useAdvanceCompilerDatabaseStore().setIsCodeExecutingDb(false)
          })
      })
      .catch(() => {
        useAdvanceCompilerDatabaseStore().setIsCodeExecutingDb(false)
      })
  }
}
/**
 * Stops the execution of the currently running query.
 *
 * If a query is currently executing, this function aborts the execution using
 * the `AbortController`. It updates the terminal session to display an
 * "Execution Stopped..." message, resets the execution timer, and sets the
 * state indicating that no query is currently executing.
 */
const stopExecuteQuery = () => {
  if (!useAdvanceCompilerDatabaseStore().isCodeExecutingDb) return

  controller?.abort()
  controller = null

  editorService.setEditorSession(IDECONSTANT.ADVCOMPILERTERMINAL, 'Execution Stopped...')
  useIdeStore().resetExecutionTime()
  useAdvanceCompilerDatabaseStore().setIsCodeExecutingDb(false)
}
/**
 * Polls for the IDE editor (Ace editor) or advanced compiler database editor to be ready.
 * This function checks whether `window['ace']` or `useIdeStore().advCompilerDatabaseEditor` is initialized,
 * and if not, retries up to 10 times with a 1-second delay between each attempt.
 * If the editor isn't ready after 10 attempts, it resets the open project ID to `null`.
 * @param count - The current number of polling attempts. Defaults to 0.
 * @returns - Returns `null` if the editor isn't initialized after 10 attempts, otherwise undefined.
 */
const pollForEditorReady = async (count: number = 0) => {
  if (!window['ace'] && !useIdeStore().advCompilerDatabaseEditor) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await pollForEditorReady(count + 1)
    }
  }
}

/**
 * Initializes the advanced database editor if it has not been initialized yet.
 *
 * This asynchronous function checks if the advanced compiler database editor
 * is already set in the IDE store. If not, it initializes the editor using
 * the Ace Editor service, configures its appearance and functionality (e.g.,
 * hides the gutter, sets it to read-only, adjusts padding and scroll margins,
 * disables indent guides and active line highlighting). Finally, it applies
 * the theme to the code editors.
 */
const initEditorsAdvDatabase = async () => {
  await pollForEditorReady()
  if (!useIdeStore().advCompilerDatabaseEditor) {
    useIdeStore().advCompilerDatabaseEditor = editorService.initAceEditor(
      IDECONSTANT.ADVCOMPILERTERMINAL
    )

    if (useIdeStore().advCompilerDatabaseEditor) {
      useIdeStore().advCompilerDatabaseEditor.renderer.setShowGutter(false)
      useIdeStore().advCompilerDatabaseEditor.setReadOnly(true)
      useIdeStore().advCompilerDatabaseEditor.renderer.setPadding(20)
      useIdeStore().advCompilerDatabaseEditor.renderer.setScrollMargin(20, 20)
      useIdeStore().advCompilerDatabaseEditor.setDisplayIndentGuides(false)
      useIdeStore().advCompilerDatabaseEditor.setHighlightActiveLine(false)
    }
  }

  editorService.codeEditorsSetTheme()
}
/**
 * Destroys the advanced database editor if it is initialized.
 *
 * This function checks if the advanced compiler database editor exists in
 * the IDE store. If it does, the function calls the `destroy` method on
 * the editor to clean up resources and then sets the editor reference to null.
 *
 */
const destroyAdvDatabaseEditor = () => {
  if (useIdeStore().advCompilerDatabaseEditor) {
    useIdeStore().advCompilerDatabaseEditor.destroy()
    useIdeStore().advCompilerDatabaseEditor = null
  }
}

/**
 * Fetching executed queries
 * @returns database queries list
 */
const queryLogsList = async () => {
  const language = useIdeStore().isLanguage
  return axios.get(`/api/code-query/list/${language}`)
}
/**
 * @param data of query information with script
 * @returns added query with id
 */
const addQueryLogs = async (data: CodeQueryRequest) => {
  return axios.post('/api/code-query', data)
}

/**
 * @param id Get detail like script
 * @returns query detail of provided id
 */
const queryDetail = async (id: number) => {
  return axios.get(`/api/code-query/${id}`)
}

/**
 * @param query details of selected query
 * @returns response
 */
const updateQueryLogs = async (query?: CodeQueryDto) => {
  const id = query?.id ? query?.id : useIdeStore().activeItem?.id
  const fileName = query?.fileName ? query?.fileName : (useIdeStore().activeItem?.name as string)
  const language = query?.language ? query?.language : useIdeStore().isLanguage
  const script = query?.id
    ? null
    : editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
  const database = query?.database
    ? query?.database
    : useAdvanceCompilerDatabaseStore().selectedDataBaseName

  if (!id) return

  const data: CodeQueryRequest = {
    language,
    script,
    database,
    fileName
  }

  return axios
    .put(`/api/code-query/${id}`, data)
    .then(() => {
      // Update Query logs data
      useAdvanceCompilerDatabaseStore().queryLogs.find((que) => {
        if (que.id === id) {
          que.database = database
          que.lastUpdate = new Date().toUTCString()
        }
      })
      // Sort Query logs data
      useAdvanceCompilerDatabaseStore().sortQueryLogs()
    })
    .catch(() => {
      useApiStore().setGlobalMsg('Failed to update query!')
    })
}

/**
 * Delete query using valid id
 * @param id query id
 * @returns response
 */
const deleteQuery = async (id: number) => {
  return axios.delete(`/api/code-query/${id}`)
}

/**
 * Init query logs by fetching it
 */
const initFetchQueryLogs = () => {
  if (useIdeStore().ideMeta?.advanceCompilerDatabase) {
    useAdvanceCompilerDatabaseStore().setIsFetchingQueryLogs(true)
    useIdeStore().setCleanUniqueActiveItemAdvIde()
    useIdeStore().setUniqueActiveItemAdvIde({ name: DEFAULTDBTAB, id: null })
    useIdeStore().setActiveFileDataBase({ id: null })
    queryLogsList()
      .then((response) => {
        useAdvanceCompilerDatabaseStore().setQueryLogs(response.data)
        useAdvanceCompilerDatabaseStore().setIsFetchingQueryLogs(false)
      })
      .catch(() => {
        useAdvanceCompilerDatabaseStore().setIsFetchingQueryLogs(false)
      })
  }
}

/**
 * @param item active tab details
 */
const setActiveTabAndEditorContent = (item: any) => {
  useIdeStore().activeItem.content = editorService
    .getEditorSession(IDECONSTANT.CODE_EDITOR)
    .getValue()

  if (item.content) {
    editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, item.content)
  } else {
    editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '')
  }

  useIdeStore().activeItem = item
}

/**
 * @param query details of selected query from logs
 */
const handleNextOrPrevActiveTab = (query: CodeQueryDto) => {
  const index = useIdeStore().uniqueActiveItemAdvIde.findIndex((item) => item.id === query.id)
  if (useIdeStore().activeItem?.id === query.id) {
    if (useIdeStore().uniqueActiveItemAdvIde.length === 1) {
      useIdeStore().setUniqueActiveItemAdvIde({ name: DEFAULTDBTAB, id: null })
      useIdeStore().setActiveFileDataBase({ id: null })
      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '')
    } else if (
      useIdeStore().uniqueActiveItemAdvIde.length > 1 &&
      index === useIdeStore().uniqueActiveItemAdvIde.length - 1
    ) {
      setActiveTabAndEditorContent(useIdeStore().uniqueActiveItemAdvIde[index - 1])
    } else if (index >= 0 && index < useIdeStore().uniqueActiveItemAdvIde.length - 1) {
      setActiveTabAndEditorContent(useIdeStore().uniqueActiveItemAdvIde[index + 1])
    }
  }
}

export default {
  setMessages,
  initPodsAdvanceCompilerDatabase,
  checkExistingPods,
  initAdvanceCompilerDatabase,
  cleanAdvanceCompilerDatabaseStore,
  checkDatabaseInfo,
  listAllDatabases,
  tryExecuteQuery,
  stopExecuteQuery,
  initEditorsAdvDatabase,
  destroyAdvDatabaseEditor,
  formatDatabaseArray,
  executeSelectedTable,
  queryLogsList,
  addQueryLogs,
  queryDetail,
  updateQueryLogs,
  deleteQuery,
  initFetchQueryLogs,
  setActiveTabAndEditorContent,
  handleNextOrPrevActiveTab
}
