<script setup lang="ts">
import { ref, watch, onBeforeUnmount } from 'vue'
import { BoardingElement } from '@/utils/ideBoardingSteps'

import BookIcon from '@/assets/images/svg/i-need-help-book-icon.svg?component'
import WorldIcon from '@/assets/images/svg/i-need-help-world-icon.svg?component'

const containerRef = ref<HTMLElement | null>(null)
// `isOpen` indicates if the 'I need help' dropdown is open or not.
const isOpen = ref(false)

/**
 * Toggle dropdown visibility
 */
const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

/**
 * @param event x
 */
const handleClickOutside = (event: MouseEvent) => {
  if (containerRef.value && !containerRef.value.contains(event.target as Node)) {
    isOpen.value = false
  }
}

watch(isOpen, (newVal) => {
  if (newVal) document.addEventListener('click', handleClickOutside)
  else document.removeEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<template>
  <div
    ref="containerRef"
    :class="[
      'relative flex min-w-fit cursor-pointer items-center justify-center gap-0.5  rounded bg-blue-500 '
    ]"
    :id="BoardingElement.IDE_HELP"
  >
    <button
      @click="toggleDropdown()"
      class="flex h-8 items-center justify-center gap-0.5 px-3 py-1 text-xs font-semibold text-white"
    >
      I need help
    </button>

    <ul
      v-if="isOpen"
      class="background-primary i_need_help_arrow absolute bottom-full right-0 z-[60] mb-4 w-full min-w-[11rem] rounded-lg p-1 shadow-[0px_5px_10px_0px_#9dacca52] dark:shadow-[0px_5px_10px_0px_#2d303752]"
    >
      <a
        href="/docs"
        target="_blank"
        class="text-mid hover:surface-secondary-header hover:text-high m-1 flex items-center gap-3 rounded p-1.5"
        @click="toggleDropdown()"
      >
        <BookIcon />Read the docs
      </a>
      <a
        href="/contact-us"
        target="_blank"
        class="text-mid hover:surface-secondary-header hover:text-high m-1 flex items-center gap-3 rounded p-1.5"
        @click="toggleDropdown()"
      >
        <WorldIcon /> Ask for help
      </a>
    </ul>
  </div>
</template>

<style scoped>
.i_need_help_arrow::after {
  content: '';
  top: 100%;
  left: 78%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 213, 11, 0);
  border-width: 10px;
  margin-left: -10px;

  @apply border-t-new-shade-0;
}

#app.dark .i_need_help_arrow::after {
  @apply border-t-new-neutral-900;
}
</style>
