<script setup lang="ts">
import authService from '@/services/auth.service'
import LogoutIcon from '@/assets/images/svg/profile-menu/logout.svg?component'

/**
 * @description Logout the user
 */
const handleLogout = async (): Promise<void> => {
  await authService.logout()
}
</script>

<template>
  <button
    class="text-button-secondary hover:surface-primary-header hover:text-high flex items-center gap-5 rounded p-2 text-sm font-medium lg:gap-3"
    @click="handleLogout"
  >
    <LogoutIcon />
    Logout
  </button>
</template>
