<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { IDEVIEWTABS_MOBILE } from '@/utils/tabs'
import { DESKTOPNAVIGATIONIDS, MOBILEIDENAVIGATIONIDS } from '@/utils/ide'
import { useIdeStore } from '@/stores/ide.store'
import { useNavStore } from '@/stores/nav.store'
import { useAuthStore } from '@/stores/auth.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import AuthMenu from '@/components/header/auth/profile/AuthMenu.vue'
import Logo from '@/components/utils/LogoComp.vue'
import SolutionsMegaMenu from '@/components/header/navigation/SolutionsMegaMenu.vue'
import ResourcesMegaMenu from '@/components/header/navigation/ResourcesMegaMenu.vue'
import HeaderProject from '@/components/ide/ide/HeaderProjectComp.vue'
import CodeSearchBar from '@/components/code/CodeSearchBar.vue'
import ProfileDropdownNew from '@/components/header/auth/profile/ProfileDropdownNew.vue'
import LogoutComp from '@/components/header/auth/profile/LogoutComp.vue'
import Header from '@/components/ide/ide/HeaderComp.vue'
import LoginComp from '@/components/header/auth/profile/LoginComp.vue'
import ExecuteComp from '@/components/ide/ide/ExecuteComp.vue'
import Menu from '@/assets/images/ide/IDEMenu.svg?component'
import ProfilePictureComp from '@/components/header/auth/profile/ProfilePictureComp.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ExecuteCompAdvCompiler from '@/components/ide/ide/ExecuteCompAdvCompiler.vue'
import { useTabStore } from '@/stores/tabs.store'

const props = defineProps({
  isMobileNav: {
    type: Boolean,
    required: false,
    default: false
  }
})
const route = useRoute()
const ideStore = useIdeStore()
const navStore = useNavStore()
const authStore = useAuthStore()
const tabStore = useTabStore()
const organisationStore = useOrganisationStore()

const ideTabScreen = ref<boolean>(false)

const isUserloggedIn = computed(() => authStore.isUserloggedIn)

const isDefaultIde = computed(() => {
  return ideStore.isDefaultIde
})

const isMarketingPage = computed(() => {
  return navStore.isMarketingPage
})

const isProductPage = computed(() => {
  return !isMarketingPage.value
})
const isIdeFullScreen = computed(() => {
  return ideStore.isFullScreen
})

const isCollapsedHeader = computed(() => {
  return navStore.isCollapsedHeader
})
const showExecuteComp = computed(() => {
  return !isDefaultIde.value && !isIdeFullScreen.value
})

const showPersonalProfilePic = computed(() => {
  return organisationStore.activeAccountId == organisationStore.personalAccountId
})

const currentAccountName = computed(() => {
  return authStore.firstName
})

const size = computed(() => {
  return isProductPage.value ? 640 : 1024
})

const showNavigationMenus = computed(() => {
  return navStore.showNavigationMenus
})

const isAdvancedCompilerRoute = computed(() => {
  return route.meta.advanceCompiler || route.meta.advanceCompilerDatabase
})

const isAdvancedCompilerDatabase = computed(() => {
  return route.meta.advanceCompilerDatabase
})

/**
 * Show Navigation Menus
 * @param event  MouseEvent
 */
const showNavigationMenusFn = (event: MouseEvent) => {
  if (event.type === 'click') {
    navStore.setShowNavigationMenus(!navStore.showNavigationMenus)
  }

  if (ideStore.currentMobileTab != null) {
    if (event.type === 'click') {
      navStore.setMobileNav(!navStore.showMobileNav)
    }
  }
}

const NAVIGATION_DROPDOWN = 'NAVIGATION_DROPDOWN'

/**
 * Close Navigation Menus Fn
 */
const closeNavigationMenu = () => {
  const isMobile = window?.innerWidth < 640

  const navbar = document.querySelector(
    isMobile ? '#mobileNav #NAVIGATION_DROPDOWN' : '#desktopNav #NAVIGATION_DROPDOWN'
  ) as HTMLElement | null

  navbar?.click()
}

/**
 * Toggles the visibility state of the mobile IDE profile navigation.
 */
const handleNavigationMobileIde = () => {
  navStore.setMobileProfileNav(!navStore.showMobileProfileNav)
}
/**
 * function used to calculate inner width of window?
 */
const handleResize = () => {
  if (window?.innerWidth >= size.value) {
    navStore.setIsCollapsed(false)
    ideStore.setMobileTab(null)
    if (ideStore.ideFullScreenMaximizedState) {
      ideStore.ideFullScreen = true
    }
  } else {
    ideStore.setMobileTab(IDEVIEWTABS_MOBILE.IDE)
    navStore.setIsCollapsed(true)
    ideStore.ideFullScreen = false
  }
}

/**
 * @param event Click event
 * If clicking outside of dropdown div, dropdown will disappear
 */
const clickEventHandler = (event: any) => {
  if (event.target.id != DESKTOPNAVIGATIONIDS.SHOW_NAVIGATION_MENU_ID) {
    navStore.setShowNavigationMenus(false)
  }
}

onMounted(() => {
  navStore.setShowNavigationMenus(false)

  if (window?.innerWidth >= size.value) {
    navStore.setIsCollapsed(false)
    ideStore.setMobileTab(null)
  } else {
    ideStore.setMobileTab(IDEVIEWTABS_MOBILE.IDE)
    navStore.setIsCollapsed(true)
  }

  window?.addEventListener('resize', handleResize)
  window?.addEventListener('click', clickEventHandler)
})

onBeforeUnmount(() => {
  window?.removeEventListener('resize', handleResize)
  window?.removeEventListener('click', clickEventHandler)
})

watch(
  () => tabStore.ioTabVisible,
  (val) => {
    if (val) {
      ideTabScreen.value = true
    } else {
      ideTabScreen.value = false
    }
  }
)

watch(
  () => isMarketingPage.value,
  (val) => {
    navStore.setShowNavigationMenus(false)
    if (val && window?.innerWidth <= 1024) {
      navStore.setIsCollapsed(true)
    }
  }
)
</script>

<template>
  <div
    v-if="!isIdeFullScreen"
    class="section-new-primary relative z-50 h-full lg:h-auto print:hidden"
    :class="[
      {
        'hidden sm:block': isProductPage && !isIdeFullScreen && !props.isMobileNav //ide (medium-desktop screen)
      },
      {
        'hidden sm:block': isProductPage && !isIdeFullScreen && !props.isMobileNav //ide (medium-desktop screen)
      },
      {
        hidden: isIdeFullScreen
      },
      {
        'background-quaternary': isProductPage && ideStore.isIdeView
      },
      {
        'background-primary': !isMarketingPage && !isProductPage
      },
      { 'shadow-sm': !isProductPage }
    ]"
  >
    <div
      class="relative flex h-full w-full flex-wrap shadow-sm sm:flex-nowrap sm:justify-start"
      :class="isProductPage ? 'stroke-primary-default border-b' : 'border-text-tertiary'"
    >
      <nav
        class="relative flex w-full justify-center p-0 sm:justify-between"
        :key="$route.fullPath"
        :id="NAVIGATION_DROPDOWN"
        :class="[
          {
            'sm:flex sm:h-full sm:items-center xl:px-2': isProductPage
          },

          {
            'xl:px[5%] w-full grow px-5 lg:flex lg:items-center lg:justify-between xl:px-[3%]':
              !isProductPage
          },
          {
            'hs-dropdown h-full [--auto-close:inside] [--offset:0] [--placement:bottom-right] [--trigger:click] ':
              isCollapsedHeader
          }
        ]"
      >
        <div
          v-if="isCollapsedHeader || !isProductPage"
          class="flex w-full items-center py-0"
          :class="
            isProductPage ? 'justify-end  sm:justify-end lg:w-auto' : 'justify-between  lg:w-fit'
          "
        >
          <div
            :class="[
              'flex  flex-row gap-2',
              { 'w-full': isProductPage },
              { 'w-auto': !isProductPage }
            ]"
          >
            <div
              :class="[
                { block: isProductPage && ideStore.currentMobileTab != null },
                { hidden: !isProductPage }
              ]"
              class="relative w-[130px] flex-row items-center justify-center gap-5 p-2 md:p-0 lg:ml-[15px] lg:flex-row lg:items-center lg:gap-[10px] xl:ml-[30px] xl:gap-[20px]"
            >
              <template v-if="isProductPage">
                <div
                  class="flex flex-row items-center"
                  :class="isAdvancedCompilerDatabase ? 'justify-start pl-2' : 'justify-center'"
                >
                  <div
                    class="flex items-center justify-center"
                    @click="(e: MouseEvent) => showNavigationMenusFn(e)"
                  >
                    <button class="h-full w-full min-w-[30px] items-center justify-center sm:flex">
                      <Menu
                        :id="MOBILEIDENAVIGATIONIDS.MENUDARKETLOGOID"
                        class="icon-ide-emphasized h-6 w-6"
                      />
                    </button>
                  </div>

                  <div
                    class="visible sm:hidden lg:block"
                    v-if="isProductPage && !isAdvancedCompilerDatabase"
                    :class="[{ invisible: !ideStore.isIdeView }]"
                  >
                    <HeaderProject />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="showExecuteComp && ideStore.isIdeView"
            class="flex w-auto justify-end py-1 pr-2 lg:hidden"
          >
            <div v-if="!isAdvancedCompilerRoute">
              <ExecuteComp :smallIcon="true" />
            </div>
            <div v-else>
              <ExecuteCompAdvCompiler :smallIcon="true" />
            </div>
          </div>

          <div
            v-if="isUserloggedIn"
            class="flex w-auto sm:hidden"
            :class="{ hidden: !isProductPage }"
          >
            <button
              type="button"
              class="flex items-center gap-2 p-2 lg:inline-flex lg:p-0"
              @click="handleNavigationMobileIde"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center gap-2">
                  <ProfilePictureComp
                    :showPersonalProfilePic="showPersonalProfilePic"
                    :isUserProfileImage="true"
                    :currentName="currentAccountName"
                  />

                  <span v-if="!isMobileNav" class="text-md flex font-medium lg:hidden">
                    {{ currentAccountName }}
                  </span>
                </div>
                <FontAwesomeIcon
                  v-if="!isMobileNav"
                  icon="fa-chevron-down"
                  class="mr-3 h-3 w-3 hs-collapse-open:rotate-180 lg:hidden"
                />
              </div>
            </button>
            <!-- <ProfileDropdownNew :isCollapsedHeader="isCollapsedHeader" :isIdeMobileNav="true" /> -->
          </div>
          <div
            v-if="!isUserloggedIn && ideStore.currentMobileTab != null"
            :class="{ hidden: !isProductPage }"
            class="pr-2"
          >
            <LoginComp
              class="h-fit rounded border-2 border-new-secondary-600 px-2 py-2 text-sm font-medium leading-none text-new-secondary-600 transition-colors hover:bg-new-secondary-50 md:px-3 md:py-4"
            />
          </div>

          <!-- Codebar (when nav collapsed) -->

          <!-- Collapse button -->
          <div
            v-if="!isCollapsedHeader || !isProductPage"
            class="flex w-full flex-row items-center justify-between"
          >
            <div class="sm:block lg:hidden">
              <Logo />
            </div>
            <div
              class="hidden justify-end sm:flex lg:hidden"
              :class="[
                { isIdeView: 'section-primary w-auto justify-end' },
                { 'w-full  justify-center py-3': !isProductPage }
              ]"
            >
              <CodeSearchBar :isWithAnimation="true" :is-full-size="false" :isMini="true" />
            </div>
            <div class="sm:block lg:hidden" :class="{ hidden: isProductPage }">
              <button
                type="button"
                :id="isCollapsedHeader ? 'hs-dropdown-header' : ''"
                class="inline-flex items-center justify-center align-middle transition-all"
                :class="isCollapsedHeader ? 'hs-dropdown-toggle' : ''"
              >
                <FontAwesomeIcon
                  icon="fa-bars"
                  class="hs-collapse-open:hidden"
                  :class="isProductPage ? 'h-[20px] w-[26px]' : 'h-8 w-8 '"
                />
                <FontAwesomeIcon
                  icon="fa-xmark"
                  class="hidden hs-collapse-open:block"
                  :class="isProductPage ? 'h-[20px] w-[26px]' : 'h-8 w-8 '"
                />
              </button>
            </div>
          </div>
        </div>
        <div
          class="border-l border-t border-text-tertiary lg:border-0 dark:border-text-secondary"
          aria-labelledby="hs-dropdown-header"
          :class="[
            {
              'hs-dropdown-menu right-0 h-full  w-[60%] border lg:flex lg:h-full lg:w-auto lg:grow lg:border-0':
                !isProductPage
            },

            {
              'section-new-primary hs-dropdown-menu hidden h-full opacity-0 transition-[opacity,margin] hs-dropdown-open:opacity-100 sm:w-[300px]':
                isCollapsedHeader && ideStore.currentMobileTab == null
            },
            {
              'section-new-primary hidden h-full opacity-0 transition-[opacity,margin] hs-dropdown-open:opacity-100':
                isCollapsedHeader && ideStore.currentMobileTab != null
            },

            {
              'w-full ': !isCollapsedHeader
            }
          ]"
        >
          <div
            class="flex flex-col"
            :class="[
              {
                'h-[calc(100%-60px)]': props.isMobileNav
              },
              {
                'justify-between sm:h-[calc(100%-60px)] sm:flex-row': isProductPage
              },
              {
                'h-[calc(100%-50px)] w-full justify-between sm:h-[calc(100%-60px)] md:h-[calc(100%-70px)] lg:h-auto lg:flex-row':
                  !isProductPage
              }
            ]"
          >
            <div
              class="flex h-auto w-full flex-col overflow-auto overflow-x-hidden sm:flex-row sm:overflow-visible"
              :class="[{ 'sm:flex-col lg:flex-row': !isProductPage }]"
            >
              <!-- Profile (mobile only) -->
              <div class="h-fit w-full" v-if="isCollapsedHeader && isUserloggedIn">
                <ProfileDropdownNew />
              </div>
              <Logo :class="[{ 'sm:block': isProductPage }, { hidden: isCollapsedHeader }]" />

              <div
                :class="[
                  'flex items-center lg:w-fit',
                  isProductPage && !isCollapsedHeader
                    ? 'relative flex-row justify-center  sm:ml-4 sm:flex-row sm:items-center'
                    : 'order-2 flex-col gap-2 sm:mt-0 sm:flex-row sm:justify-start lg:order-1 lg:items-center lg:justify-start lg:gap-4',
                  { 'w-full': !isProductPage }
                ]"
              >
                <template v-if="isProductPage && !isCollapsedHeader">
                  <div @click="(e: MouseEvent) => showNavigationMenusFn(e)">
                    <button class="hidden h-full w-full min-w-[30px] items-center sm:flex">
                      <Menu
                        :id="DESKTOPNAVIGATIONIDS.SHOW_NAVIGATION_MENU_ID"
                        class="icon-ide-emphasized h-6 w-6"
                      />
                    </button>
                  </div>

                  <div
                    class="ml-2 sm:block"
                    v-if="ideStore.isIdeView && !isAdvancedCompilerDatabase"
                  >
                    <HeaderProject />
                  </div>
                </template>

                <template v-if="!isProductPage && !isCollapsedHeader">
                  <hr
                    class="border-text-tertiary sm:hidden dark:border-text-secondary"
                    v-if="isUserloggedIn"
                  />
                </template>

                <!-- Shared content -->
                <div
                  v-if="isProductPage && !isCollapsedHeader ? showNavigationMenus : true"
                  @mouseenter="(e: MouseEvent) => showNavigationMenusFn(e)"
                  @mouseleave="(e: MouseEvent) => showNavigationMenusFn(e)"
                  :class="
                    isProductPage && !isCollapsedHeader && !ideStore.currentMobileTab
                      ? 'stroke-primary-default background-quaternary absolute left-0 top-10 inline-flex h-fit w-32 flex-col items-start justify-start gap-1 rounded-[7px] border p-1.5 shadow'
                      : 'order-2  flex w-full flex-col gap-2 [--adaptive:none]  [--strategy:static] [--trigger:click] lg:flex lg:h-full lg:flex-row lg:items-center lg:gap-0 lg:[--strategy:absolute] '
                  "
                >
                  <!-- Solutions -->
                  <div
                    class="hs-dropdown w-full [--adaptive:none] [--strategy:static] [--trigger:click] lg:flex lg:flex-row lg:items-center lg:[--strategy:absolute]"
                  >
                    <button
                      :id="DESKTOPNAVIGATIONIDS.SHOW_NAVIGATION_MENU_ID"
                      type="button"
                      class="flex w-full items-center justify-between px-3 py-1 lg:rounded-md lg:px-2"
                      :class="[
                        isProductPage && !isCollapsedHeader
                          ? 'hover:surface-primary-header py-1.5 text-[14px]'
                          : 'justify-between text-sm lg:font-normal',
                        {
                          'hover:color-secondary-700  text-black hover:bg-new-neutral-100 dark:text-white dark:hover:bg-transparent hover:dark:text-white ':
                            !isProductPage
                        },
                        {
                          'mr-2': !isProductPage
                        }
                      ]"
                    >
                      Solutions
                      <FontAwesomeIcon
                        :icon="
                          isProductPage && !isCollapsedHeader
                            ? 'fa-chevron-right'
                            : 'fa-chevron-down'
                        "
                        class="ml-2 mr-2 h-3 w-3 lg:mr-0 lg:h-2.5 lg:w-2.5"
                      />
                    </button>
                    <SolutionsMegaMenu :isProductPage="isProductPage" />
                  </div>

                  <!-- Resources -->
                  <div
                    class="hs-dropdown w-full [--adaptive:none] [--strategy:static] [--trigger:click] lg:flex lg:items-center lg:[--strategy:absolute]"
                  >
                    <button
                      :id="DESKTOPNAVIGATIONIDS.SHOW_NAVIGATION_MENU_ID"
                      type="button"
                      class="flex w-full items-center justify-between px-3 py-1 lg:rounded-md lg:px-2"
                      :class="[
                        isProductPage && !isCollapsedHeader
                          ? 'hover:surface-primary-header py-1.5 text-[14px]'
                          : 'justify-between text-sm lg:font-normal',
                        {
                          'hover:color-secondary-700  text-black hover:bg-new-neutral-100 dark:text-white dark:hover:bg-transparent hover:dark:text-white ':
                            !isProductPage
                        },
                        {
                          'mr-2': !isProductPage
                        }
                      ]"
                    >
                      Resources
                      <FontAwesomeIcon
                        :icon="
                          isProductPage && !isCollapsedHeader
                            ? 'fa-chevron-right'
                            : 'fa-chevron-down'
                        "
                        class="ml-2 mr-2 h-3 w-3 lg:mr-0 lg:h-2.5 lg:w-2.5"
                      />
                    </button>
                    <ResourcesMegaMenu :isProductPage="isProductPage" />
                  </div>

                  <!-- Pricing -->
                  <div class="w-full">
                    <RouterLink
                      class="flex w-full items-center px-3 py-1 lg:rounded-md lg:px-2"
                      to="/pricing"
                      :class="[
                        isProductPage && !isCollapsedHeader
                          ? 'hover:surface-primary-header py-1.5 text-[14px]'
                          : 'text-sm lg:font-normal',
                        {
                          'hover:color-secondary-700  text-black hover:bg-new-neutral-100 dark:text-white dark:hover:bg-transparent hover:dark:text-white ':
                            !isProductPage
                        }
                      ]"
                    >
                      Pricing
                    </RouterLink>
                  </div>
                </div>
              </div>

              <div
                v-if="ideStore.isIdeView && !isCollapsedHeader"
                class="flex-end flex w-full items-center justify-center"
              >
                <Header class="hidden md:mx-5 md:block" />
              </div>
              <div
                v-if="showExecuteComp && ideStore.isIdeView"
                class="hidden items-center justify-end gap-2 py-1 sm:flex sm:justify-center xl:w-auto"
              >
                <div v-if="!isAdvancedCompilerRoute">
                  <ExecuteComp />
                </div>
                <div v-else>
                  <ExecuteCompAdvCompiler />
                </div>
              </div>
              <!-- Search -->
              <div
                class="ml-2 hidden w-auto grow items-center justify-end gap-2 py-1 md:order-2 md:ml-5 md:flex lg:min-w-[180px] xl:w-auto"
                :class="[
                  { 'py-3 md:hidden lg:flex': !isProductPage },
                  { 'md:justify-center': isMarketingPage },
                  { 'sm:flex sm:justify-end': !isMarketingPage }
                ]"
              >
                <CodeSearchBar :isWithAnimation="true" :is-full-size="false" :isMini="true" />
              </div>

              <!-- Auth -->
              <div
                class="align-center order-1 ml-2 hidden items-center justify-center gap-4 py-2 sm:order-2 sm:ml-5 sm:flex sm:py-0"
              >
                <AuthMenu />
              </div>
            </div>
            <div
              :class="[{ 'sm:block lg:hidden': !isProductPage }, { 'sm:hidden': isProductPage }]"
              @click="closeNavigationMenu"
            >
              <hr class="border-text-tertiary dark:border-text-secondary" />
              <LogoutComp class="px-3 py-4" v-if="isUserloggedIn" />
              <LoginComp class="px-3 py-4" v-else />
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
