import type { INIT_NEXT_ACTION } from '@/components/organisation/enum/advanceCompiler.enums'
import type { CodeQueryDto } from '@/services/ide/advanceCompilerDatabase.service'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface IDatabaseList {
  name: string
}

export const useAdvanceCompilerDatabaseStore = defineStore('advanceCompilerDatabase', () => {
  const databaseLogMessages = ref<string[]>([])

  const sessionId = ref<string | null>(null)
  const kurukkuKuriId = ref<string | null>(null)
  const serverErrorsMessages = ref<string | null>(null)
  const startLoggingMessages = ref<boolean>(false)
  const isPodInitializationCompleted = ref<boolean>(false)
  const isPodInitializationInProgress = ref<boolean>(false)
  const isPodInitializationHasErrors = ref<boolean>(false)
  const selectedDataBaseName = ref<string | null>(null)
  const isCodeExecutingDb = ref<boolean>(false)
  const logExecuteQueryResult = ref<any | null>(null)
  const databaseListNames = ref<any[]>([])

  const hideOutputEditor = ref<boolean>(true)
  const loadingPods = ref<boolean>(false)
  const socketCompleted = ref<boolean>(false)

  const databaseInfo = ref<any | null>(null)
  const databaseInstanceName = ref<string>('')

  const loadingDatabaseList = ref<boolean>(false)
  const databaseListFetchCompleted = ref<boolean>(false)
  const executeQueryResponse = ref<string | null>(null)
  const initNextAction = ref<INIT_NEXT_ACTION | null>(null)

  const queryLogs = ref<CodeQueryDto[]>([])
  const isFetchingQueryLogs = ref<boolean>(true)

  const socketInProgressDb = computed(() => {
    if (isPodInitializationCompleted.value == false) {
      return true
    } else if (isPodInitializationHasErrors.value == true && isPodInitializationCompleted.value) {
      return false
    } else if (loadingPods.value) return true
    else if (socketCompleted.value) {
      return false
    } else {
      return true
    }
  })

  const socketLogsInProgress = computed(() => {
    if (socketInProgressDb.value) {
      if (databaseLogMessages.value.length > 0) {
        return true
      }
      return false
    }
    return false
  })

  /**
   * Sets the completion state of the socket connection.
   *
   * This function updates the `socketCompleted` reactive value to indicate
   * whether the socket connection is currently completed or not.
   * @param val - The current completion state of the socket.
   * `true` if completed, `false` if not.
   */
  const setSocketCompleted = (val: boolean) => {
    socketCompleted.value = val
  }

  /**
   * Updates the databaseLogMessages ref based on the provided input.
   *
   * If the input is an array, this function resets the `databaseLogMessages` array.
   * If the input is a string, it appends the message to the existing array of
   * log messages.
   * @param message - The log message(s) to set. Can be a
   *   single message string or an array of messages.
   */
  const setDatabaseLogMessages = (message: string | string[]) => {
    if (Array.isArray(message)) {
      databaseLogMessages.value = [] // Reset the databaseLogMessages array
    } else {
      databaseLogMessages.value = [...databaseLogMessages.value, message] // Add the log message to the array
    }
  }

  /**
   * Sets the current session ID.
   * This function updates the `sessionId` reactive value with the provided
   * session ID. If the value is `null`, it indicates that there is no current
   * session ID.
   * @param val - The session ID to set, or `null` if there
   * is no session ID.
   */
  const setSessionId = (val: string | null) => {
    sessionId.value = val
  }

  /**
   * Sets the current kurukku Kuri ID.
   *
   * This function updates the `kurukkuKuriId` reactive value with the
   * provided Kuri ID. If the value is `null`, it indicates that there is
   * no current Kuri ID.
   * @param val - The Kuri ID to set, or `null` if there
   * is no Kuri ID.
   */
  const setkurukkuKuriId = (val: string | null) => {
    kurukkuKuriId.value = val
  }

  /**
   * Sets the current server error message.
   *
   * This function updates the `serverErrorsMessages` reactive value with
   * the provided error message. If the value is `null`, it indicates that
   * there is no current error message.
   * @param val - The current error message, or `null` if
   * there is no error.
   */
  const setServerErrorMessages = (val: string | null) => {
    serverErrorsMessages.value = val
  }
  /**
   * Sets the state for starting or stopping log message recording.
   *
   * This function updates the `startLoggingMessages` reactive value to
   * indicate whether logging of messages should begin or stop.
   * @param val - The value to set for starting or stopping logging.
   */
  const setStartLoggingMessages = (val: boolean) => {
    startLoggingMessages.value = val
  }

  /**
   * Sets the state of pod initialization completion.
   * @param val - The value to set for pod initialization completion.
   */
  const setIsPodInitializationCompleted = (val: boolean) => {
    isPodInitializationCompleted.value = val
  }

  /**
   * Sets the state of pod initialization progress.
   *
   * This function updates the `isPodInitializationInProgress` reactive value
   * to indicate whether the pod initialization is currently in progress.
   * @param val - The current state of pod initialization.
   * `true` if initialization is in progress, `false` otherwise.
   */
  const setIsPodInitializationInProgress = (val: boolean) => {
    isPodInitializationInProgress.value = val
  }

  /**
   * Sets the error state of pod initialization.
   *
   * This function updates the `isPodInitializationHasErrors` reactive value
   * to indicate whether there are errors during the pod initialization process.
   * @param val - The current error state of pod initialization.
   * `true` if there are errors, `false` otherwise.
   */
  const setIsPodInitializationHasErrors = (val: boolean) => {
    isPodInitializationHasErrors.value = val
  }

  /**
   * Updates the database list with the provided database names.
   * @param databases - An array of database objects to be added to the database list array.
   */
  const setDatabaseListNames = (databases: any[]): void => {
    databaseListNames.value = [...databases]
  }

  /**
   * Sets the selected database name.
   * @param value - The name of the selected database, or null to clear the selection.
   */
  const setSelectedDataBaseName = (value: string | null) => {
    if (selectedDataBaseName.value?.includes(value as string)) {
      selectedDataBaseName.value = null
    } else {
      selectedDataBaseName.value = value
    }
  }

  /**
   * Sets the state indicating whether a code execution is currently in progress.
   * @param value - The new state indicating if code execution is active (true) or not (false).
   */
  const setIsCodeExecutingDb = (value: boolean) => {
    isCodeExecutingDb.value = value
  }

  /**
   * Sets the log for the result of the executed query.
   * @param val The value to log as the result of the executed query.
   */
  const setLogExecuteQueryResult = (val: any) => {
    logExecuteQueryResult.value = val
  }
  /**
   * Sets the visibility state of the output editor.
   * @param val The value to set for the output editor's visibility (true to hide, false to show).
   */
  const setHideOutputEditor = (val: any) => {
    hideOutputEditor.value = val
  }

  /**
   * Sets the loading state for pods.
   *
   * This function updates the `loadingPods` reactive value to indicate
   * whether pods are currently loading.
   * @param val - The loading state to set.
   * `true` if pods are loading, `false` if not.
   */
  const setLoadingPods = (val: boolean) => {
    loadingPods.value = val
  }
  /**
   * Updates the `databaseInfo` with the provided value.
   * @param  val - The value to be assigned to `databaseInfo`.
   */
  const setDatabaseInfo = (val: any) => {
    databaseInfo.value = val
  }
  /**
   * Sets the loading state for the database list.
   * @param  val - A boolean value indicating whether the database list is loading (true) or not (false).
   */
  const setLoadingDatabaseList = (val: boolean) => {
    loadingDatabaseList.value = val
  }
  /**
   * Sets the completion state of fetching the database list.
   * @param  val - A boolean value indicating whether the database list fetch is completed (true) or not (false).
   */
  const setDatabaseListFetchCompleted = (val: boolean) => {
    databaseListFetchCompleted.value = val
  }
  /**
   * Sets the value of the `databaseInstanceName`.
   * @param  val - The new value to assign to `databaseInstanceName`.
   */
  const setDatabaseInstanceName = (val: string) => {
    databaseInstanceName.value = val
  }
  /**
   * Sets the value of `executeQueryResponse`.
   * @param  val - The new value to set for `executeQueryResponse`.
   */
  const setExecuteQueryResponse = (val: string | null) => {
    executeQueryResponse.value = val
  }

  /**
   * Sets the value of `initNextAction`.
   * @param val - The new value to set for `initNextAction`.
   */
  const setInitNextAction = (val: INIT_NEXT_ACTION) => {
    initNextAction.value = val
  }

  /**
   * @param val array of query logs
   */
  const setQueryLogs = (val: CodeQueryDto[]) => {
    queryLogs.value = val
    sortQueryLogs()
  }

  /**
   * Sort query logs based on last update time
   */
  const sortQueryLogs = () => {
    queryLogs.value.sort((a, b) => {
      const aTime = new Date(a.lastUpdate).getTime()
      const bTime = new Date(b.lastUpdate).getTime()

      return bTime - aTime
    })
  }

  /**
   * @param value boolean value
   */
  const setIsFetchingQueryLogs = (value: boolean = false) => {
    isFetchingQueryLogs.value = value
  }

  return {
    databaseLogMessages,
    setDatabaseLogMessages,
    sessionId,
    setSessionId,
    kurukkuKuriId,
    setkurukkuKuriId,
    serverErrorsMessages,
    setServerErrorMessages,
    startLoggingMessages,
    setStartLoggingMessages,
    setIsPodInitializationCompleted,
    setIsPodInitializationHasErrors,
    setIsPodInitializationInProgress,
    isPodInitializationCompleted,
    isPodInitializationHasErrors,
    isPodInitializationInProgress,
    databaseListNames,
    setDatabaseListNames,
    selectedDataBaseName,
    setSelectedDataBaseName,
    isCodeExecutingDb,
    setIsCodeExecutingDb,
    logExecuteQueryResult,
    setLogExecuteQueryResult,
    hideOutputEditor,
    setHideOutputEditor,
    loadingPods,
    setLoadingPods,
    socketCompleted,
    setSocketCompleted,
    socketInProgressDb,
    socketLogsInProgress,
    databaseInfo,
    setDatabaseInfo,
    loadingDatabaseList,
    setLoadingDatabaseList,
    databaseListFetchCompleted,
    setDatabaseListFetchCompleted,
    databaseInstanceName,
    setDatabaseInstanceName,
    executeQueryResponse,
    setExecuteQueryResponse,
    initNextAction,
    setInitNextAction,
    queryLogs,
    setQueryLogs,
    sortQueryLogs,
    isFetchingQueryLogs,
    setIsFetchingQueryLogs
  }
})
